import React, { Component } from 'react'
import { Form, Button, Container, Row, Col, Figure } from 'react-bootstrap';
import logo from '../../../images/aawaz-logo.png';
import profilePic from '../../../images/profilePic.png';
import './profile-page.css';
import { AddRj, createGuest, UpdateRj, UpdateGuest } from '../../../services/serviceLive'
import { ToastContainer, toast } from 'react-toastify';
import Sidebar from '../Sidebar/Sidebar'

class rjProfilePage extends Component {

    state = {
        firstName: "",
        lastName: "",
        username: "",
        bio: "",
        profile_image: "",
        profileImageUrl: "",
        password: "",
        email: "",
        role: "",
        profileImageView: ""
    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }
    profileImageChange = (event) => {
        this.setState({ profileImageView: undefined, profile_image: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ profile_image: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ profileImageView: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    submitHandler = (e) => {
        e.preventDefault()
        // console.log("Live show state", this.state)
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                default:
                    formData.append(`${key}`, value)
            }
        }
        if (this.state.role === "rj") {
            AddRj(formData)
                .then((response) => {
                    console.log("rj profiel response", response)
                    toast.success(` Rj created  successfully.`)
                    this.setState({
                        firstName: "",
                        lastName: "",
                        username: "",
                        bio: "",
                        profile_image: "",
                        profileImageUrl: "",
                        password: "",
                        email: "",
                        role: "",
                        profileImageView: ""
                    })
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value[0]}. `
                        }
                        toast.error(err)
                    }
                    console.log(error)
                })
        } else if (this.state.role === "guest") {

            createGuest(formData)
                .then((response) => {
                    console.log("guest profiel response", response)
                    toast.success(` guest created successfully.`)
                    this.setState({
                        firstName: "",
                        lastName: "",
                        username: "",
                        bio: "",
                        profile_image: "",
                        profileImageUrl: "",
                        password: "",
                        email: "",
                        role: "",
                        profileImageView: ""
                    })
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value[0]}. `
                        }
                        toast.error(err)
                    }
                    console.log(error)
                })
        }
        else {
            toast.error(`please select role`)
        }
    }
    Updatehandeler = () => {
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case "password":
                    if (this.state.password !== "") {
                        formData.append(`${key}`, value)
                    } else {

                    }
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        if (this.props.editedData.uniqueSlug.search("gt") === 0) {
            UpdateGuest(this.props.editedData.uniqueSlug, formData)
                .then((response) => {
                    toast.success(` ${response.name}updated successfully.`)
                    this.setState({
                        firstName: "",
                        lastName: "",
                        username: "",
                        bio: "",
                        profile_image: "",
                        profileImageUrl: "",
                        password: "",
                        email: "",
                        role: "",
                        profileImageView: ""
                    })
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value[0]}. `
                        }
                        toast.error(err)
                    } else if (error.response.status === 404) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value}. `
                        }
                        toast.error(err)
                    }
                    console.log(error)
                })
        } else if (this.props.editedData.uniqueSlug.search("rj") === 0) {
            UpdateRj(this.props.editedData.uniqueSlug, formData)
                .then((response) => {
                    toast.success(` ${response.name}updated successfully.`)
                    this.setState({
                        firstName: "",
                        lastName: "",
                        username: "",
                        bio: "",
                        profile_image: "",
                        profileImageUrl: "",
                        password: "",
                        email: "",
                        role: "",
                        profileImageView: ""
                    })
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value[0]}. `
                        }
                        toast.error(err)
                    } else if (error.response.status === 404) {
                        let err = "";
                        for (let [key, value] of Object.entries(error.response.data)) {
                            err = `${key} :${value}. `
                        }
                        toast.error(err)
                    }
                    console.log(error)
                })
        }

    }
    componentDidMount = () => {
        if (this.props.editedData) {


            console.log("edited rj profile", this.props.editedData)
            this.setState({
                firstName: this.props.editedData.user.firstName || "",
                lastName: this.props.editedData.user.lastName || "",
                username: this.props.editedData.user.username,
                email: this.props.editedData.user.email,
                bio: this.props.editedData.bio,
                profile_image: this.props.editedData.profileImageUrl,
                profileImageUrl: this.props.editedData.profileImageUrl,
                role: this.props.editedData.role,
            })

            if (this.props.editedData.uniqueSlug.search("gt") === 0) {
                this.setState({ role: "guest" })
            } else if (this.props.editedData.uniqueSlug.search("rj") === 0) {
                this.setState({ role: "rj" })
            }
        }
    }

    render() {
        console.log(this.state)
        return (
            <React.Fragment>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    position="top-center" />
                <Container>
                    <Row>
                        {this.props.upadte === "true" ? null :
                            <Sidebar />}

                        <Col className="centerAlign">
                            <Form className="profile-form" onSubmit={this.submitHandler}>
                                {/* <img src={profilePic} className="profile-pic" alt="Profile picture" /> */}
                                {/* <Form.Label>Featured Image: </Form.Label> */}


                                {this.state.profileImageView ?
                                    <Figure>
                                        <Figure.Image src={this.state.profileImageView || this.state.profileImageUrl} thumbnail className="profile-pic" />
                                    </Figure> : <img src={profilePic} className="profile-pic" alt="Profile picture" />
                                }
                                <Form.File type="file" name="profile_image" label="Profile Image Upload" accept="image/*" style={{ width: '100px', marginLeft: 'auto', marginRight: 'auto' }} onChange={this.profileImageChange} />

                                {/* Role */}
                                <Form.Group controlId="exampleForm.ControlSelect1">
                                    <Form.Control className="login-input" as="select" name="role" value={this.state.role} onChange={this.handleChange}>
                                        <option defaultValue value="">Select Role</option>
                                        <option value="rj">rj</option>
                                        <option value="guest">guest</option>

                                    </Form.Control>
                                </Form.Group>
                                {/* Profile Image Url */}
                                <Form.Group controlId="formBasicText">
                                    <Form.Control className="login-input" type="text" placeholder="Profile Image Url " name="profileImageUrl" value={this.state.profileImageUrl} onChange={this.handleChange} />
                                </Form.Group>
                                {/* FIRST NAME */}

                                <Form.Group controlId="formBasicText">
                                    <Form.Control className="login-input" type="text" placeholder="First Name" name="firstName" value={this.state.firstName} onChange={this.handleChange} />
                                </Form.Group>
                                {/* Lat Name */}
                                <Form.Group controlId="formBasicText">
                                    <Form.Control className="login-input" type="text" placeholder="Last Name" name="lastName" value={this.state.lastName} onChange={this.handleChange} />
                                </Form.Group>
                                {/* USER NAME */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder=" User Name" name="username" value={this.state.username} onChange={this.handleChange} />
                                </Form.Group>
                                {/* password */}
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control className="login-input" type="password" placeholder="Password" name="password" value={this.state.password} onChange={this.handleChange}
                                    />
                                </Form.Group>
                                {/* email */}
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control className="login-input" type='email' placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} />
                                </Form.Group>
                                {/* BIO */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" as="textarea" placeholder="Bio" name="bio" value={this.state.bio} onChange={this.handleChange} />
                                </Form.Group>

                                {this.props.upadte === "true" ? <Button className="login-button" variant="outline-light" onClick={this.Updatehandeler}>
                                    Update Profile
                                  </Button> : <Button className="login-button" variant="outline-light" type="submit">
                                    Save Profile
                                  </Button>}

                            </Form>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default rjProfilePage
import http from "../http-common";
import auth from "./authentication";
import axios from "axios";

const baseURL = "https://preprod.aawaz.com/api";

const getAll = () => {
  return http.get(`${baseURL}/section/?all=True`, { auth: auth });
};

const getAllSectionsPagination = (page = 1) => {
  return http.get(`${baseURL}/section/?page=${page}`, { auth: auth });
};

const getSection = (id) => {
  return http.get(`${baseURL}/section/${id}/`, { auth: auth });
};

const createSection = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/section/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: auth,
    data: data,
  });
  // return http.post(`${baseURL}/section/`, {auth: auth}, {data: data});
};
const createdashboardV3 = (data) => {
  return axios({
    method: "POST",
    url: `${baseURL}/dashboard-v3/`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    auth: auth,
    data: data,
  });
  // return http.post(`${baseURL}/section/`, {auth: auth}, {data: data});
};
const getAlldashboardV3 = () => {
  return http.get(`${baseURL}/dashboard-v3/`, { auth: auth });
};
const removedashboardV3 = (id) => {
  return http.delete(`${baseURL}/dashboard-v3/${id}/`, { auth: auth });
};

const updatedashboardV3 = (id, data) => {
  return http.patch(`${baseURL}/dashboard-v3/${id}/`, data, { auth: auth });
};
const getdashboardV3Byid = (id) => {
  return http.get(`${baseURL}/dashboard-v3/${id}/`, { auth: auth });
};

const updateSection = (id, data) => {
  return http.put(`${baseURL}/section/${id}/`, { auth: auth, data: data });
};

const removeSection = (id) => {
  return http.delete(`${baseURL}/section/${id}/`, { auth: auth });
};

const removeAllSection = () => {
  return http.delete(`${baseURL}/section/`, { auth: auth });
};

const findByTitleSection = (title) => {
  return http.get(`${baseURL}/section?title=${title}`, { auth: auth });
};
const sectionSequnce = (data) => {
  let sequencedata = {
    sections: data,
  };
  console.log("sequencedata", sequencedata);
  return http.post(`${baseURL}/section/order_sections/`, sequencedata, {
    auth: auth,
  });
};
export {
  getAll,
  getAllSectionsPagination,
  getSection,
  createSection,
  updateSection,
  removeSection,
  removeAllSection,
  findByTitleSection,
  sectionSequnce,
  baseURL,
  auth,
  createdashboardV3,
  getAlldashboardV3,
  removedashboardV3,
  updatedashboardV3,
  getdashboardV3Byid,
};

import React, { Component } from 'react'
import axios from 'axios';
import { Form, Button, Figure } from 'react-bootstrap'
import { baseURL } from "../../../services/serviceBadges";
// import moment from 'moment';
import Loader from 'react-loader-spinner';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import auth from '../../../services/authentication';
import { createLanguages, getBySlugLanguages, EditLanguages } from '../../../services/serviceLanguages';

class AddLanguage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            original: "",
        }
    }


    changeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }


    submitHandler = (e) => {
        e.preventDefault()
        // console.log(this.state)
        this.setState({ isLoading: true })
        // this.handleClose()
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'show_slug':
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }

        axios({
            url: `${baseURL}/app-language/`,
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            auth: auth,
        })
            // createLanguages(this.state)
            .then(response => {
                this.setState({ isLoading: true })
                if (response.status === 201) {
                    toast.success(`Language ${response.data.name} is created successfully.`)
                }
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 400) {
                    toast.error(JSON.stringify(error.response.data))
                    this.setState({ isLoading: false })
                }
                console.log(error)
            })

    }
    EditLang = () => {
        console.log("edit languages", this.state)
        this.setState({ isLoading: true })
        // this.handleClose()
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'name':
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }

        axios({
            url: `${baseURL}/app-language/${this.props.data.uniqueSlug}/`,
            method: "PATCH",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData,
            auth: auth,
        })
            // EditLanguages(this.props.data.uniqueSlug, data)
            .then((res) => {
                toast.success(`Language ${res.data.name} is updated successfully.`)
                // console.log("edit", res.data)
                this.props.toggleEdit()
            })
            .catch((err) => {
                // console.log(err)
                toast.success(`Language updated failed.`)
            })
    }

    componentDidMount() {
        if (this.props.data) {

            getBySlugLanguages(this.props.data.uniqueSlug)
                .then((res) => {
                    this.setState({
                        name: res.data.name,
                        original: res.data.original,
                    })
                    console.log(res.data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }

    render() {
        const { name, original } = this.state
        return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    position="top-center" />

                <h2>Add New Language</h2>
                <Form onSubmit={this.submitHandler}>
                    <Form.Group>
                        <Form.Label>Language Name: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter Language Name here" name="name" value={name} onChange={this.changeHandler} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Language Symbol: <span className="asterisk"> *</span></Form.Label>
                        <Form.Control type="text" placeholder="Enter Language Symbol here" name="original" value={original} onChange={this.changeHandler} />
                    </Form.Group>

                    {this.props.Edit ? <Button onClick={this.EditLang} variant="info">Edit</Button> : !this.state.isLoading ? <Button type="submit" variant="info">Publish</Button> : <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />}
                </Form>
            </div>
        )
    }
}

export default AddLanguage

import React, { Component } from 'react';
import { Form, Col, Button, Figure } from 'react-bootstrap';

import { createBanner } from "../../../services/serviceBanner";
import { getAllBadge } from '../../../services/serviceBadges';
import { getAllPlatform } from '../../../services/servicesPlatform';
import { getAllBannerType } from '../../../services/serviceBannerType';
import { getAllBannerTags } from '../../../services/serviceBannerTags';
// import { getAllLanguages } from '../../../services/serviceLanguages';

import DatePicker from "react-datepicker";
import { ChromePicker } from 'react-color'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import moment from 'moment';

class AddBanner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: "",
      short_url: "",
      dateTime: new Date(),
      start_datetime: `${moment(new Date()).format()}`,
      dateTime1: new Date(),
      end_datetime: `${moment(new Date()).format()}`,
      sequence: 1,
      BannerButtonView: undefined,
      button_icon: undefined,
      bannerBackgroundImageView: undefined,
      background_image: undefined,
      background_color: "#04FF34",
      button_color: "#05E4F9",
      button_text_color: "#ffff00",
      button_title: "",
      text_color: "#000000",
      language: "hi",
      action_content: "",
      action_slug: "",
      platform_slug: "",
      content_slug: "",
      tag_slug: "",
      action: [],
      badges: [],
      platforms: [],
      tags: [],
      languages: [],
      episodes:[],
      shows:[],
      channels:[]
    }
  }


  onDrag(background_color, c) {
    this.setState({
      background_color
    })
  }

  componentDidMount = () => {
    // getAllBanner()
    // .then(response => {
    //   this.setState({ banners: response.data })
    //   // console.log(response.data)
    // })
    // .catch(error => {
    //   console.log(error)
    // });
    getAllBannerType()
      .then(response => {
        this.setState({ action: response.data.results })
        //  console.log(response.data.results)
      })
      .catch(error => {
        console.log(error)
      });
    getAllBannerTags()
      .then(response => {
        // let newOptions = []
        let bannerTags = response.data.map(tag => {
          return { value: tag.uniqueSlug, label: tag.name }
        })
        this.setState({ tags: bannerTags })
        // console.log(bannerTags)
      })
      .catch(error => {
        console.log(error)
      });
    getAllPlatform()
      .then(response => {
        this.setState({ platforms: response.data })
        //  console.log(response.data.results)
      })
      .catch(error => {
        console.log(error)
      });

    // getShowsDropDowns()
    // .then(response => {
    //   this.setState({ action_content: response.data })
    //    console.log(response.data)
    // })
    // .catch(error => {
    //   console.log(error)
    // });

    // getAllLanguages()
    //   .then(response => {
    //     this.setState({ languages: response.data.results })
    //   })
    //   .catch(error => {
    //     console.log(error)
    //   });

    getAllBadge()
      .then(response => {
        this.setState({ badges: response.data.results })
      })
      .catch(error => {
        console.log(error)
      });
  }



  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  onBannerButtonIconChange = (event) => {
    this.setState({ BannerButtonView: undefined, button_icon: undefined })
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ button_icon: event.target.files[0] })
      reader.onload = (e) => {
        this.setState({ BannerButtonView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }


  onBannerBackgroundImageChange = (event) => {
    this.setState({ bannerBackgroundImageView: undefined, background_image: '' })
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ background_image: event.target.files[0] })
      reader.onload = (e) => {
        this.setState({ bannerBackgroundImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  fileSelectedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0]
    })
  }

  tagsSelectHandler = (value) => {
    // console.log("value: ", value)
    if (value === null) {
      this.setState({ tag_slug: "" })
      return
    }
    let tags = value.map(item => item.value)
    // console.log("tags: ", tags.join())
    this.setState({ tag_slug: tags.join() })
  }

  handleDateChange = date => {
    this.setState({
      start_datetime: moment(date).format(),
      dateTime: date
    });
  };


  handleDateChange1 = date => {
    this.setState({
      end_datetime: moment(date).format(),
      dateTime1: date
    });
  };

  submitHandler = (e) => {
    e.preventDefault()
    // console.log(this.state)
    this.setState({ isLoading: true })
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case 'sequence':
          formData.append(`${key}`, parseInt(value))
          break;
        case 'bannerBackgroundImageView':
        case 'BannerButtonView':
        case 'artists':
        case 'deepLink':
        case 'badges':
        case 'banners':
        case 'platforms':
        case 'action':
        // case 'action_content':
        case 'tags':
        // case 'content_slug':
        case 'channels':
        case 'dateTime':
        case 'dateTime1':
        case 'isLoading':
          break;
        default:
          formData.append(`${key}`, value)
      }
    }
    for (var [key, value] of formData.entries()) {
      if (value === '' || value === undefined || value === {}) {
        this.setState({ isLoading: false })
        return toast.error(`${key} is required!`)
      }
      // console.log(key + ': ' + value);
    }
    createBanner(formData)
      .then(response => {
        if (response.status === 201) {
          toast.success(`New Banner '${response.data.name}' is created successfully.`)
          this.setState({ isLoading: false })
          // window.location.reload(false);
        }
      })
      .catch(error => {
        console.log(error.response)
        if (error.response.status === 400) {
          let err = "";
          for (let [, value] of Object.entries(error.response.data)) {
            err = `${err} ${value[0]}. `
          }
          toast.error(err)
          this.setState({ isLoading: false })
        }
        console.log(error)
      })

  }

  btnColorPickerHandler = (color) => {
    this.setState({ button_color: color.hex })
  }

  btnTxtColorPickerHandler = (color) => {
    this.setState({ button_text_color: color.hex })
  }

  bgColorPickerHandler = (color) => {
    this.setState({ background_color: color.hex })
  }

  txtColorPickerHandler = (color) => {
    this.setState({ text_color: color.hex })
  }

  // onBannerActionChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value })
  //   switch(e.target.value){
  //     default:
        
  //   }
  // }

  render() {

    const { name, short_url, background_image_url, content, start_datetime, end_datetime, background_color, button_color, button_text_color, text_color,
      button_title, sequence, height, width, badges, action, action_content, content_slug, platforms, tags, languages, language } = this.state
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center" />
        <h2>Add New Banner</h2>


        <Form onSubmit={this.submitHandler}>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>Name: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" placeholder="Enter Name here" name="name" value={name} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Short Url: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" placeholder="Enter Short Url" name="short_url" value={short_url} onChange={this.changeHandler} />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Row>
              <Col>
                <Form.Label>Start Datetime:  <span className="asterisk"> *</span></Form.Label>
                <DatePicker
                  name="start_datetime"
                  selected={this.state.dateTime}
                  onChange={this.handleDateChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
              <Col>
                <Form.Label>End Datetime:  <span className="asterisk"> *</span></Form.Label>
                <DatePicker
                  name="end_datetime"
                  selected={this.state.dateTime1}
                  onChange={this.handleDateChange1}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Row>              
              <Col>
                <Form.Label>Background Color:  <span className="asterisk"> *</span></Form.Label>
                {/* <Form.Control type="text" min="1" placeholder="Background Color" name="background_color" value={background_color} onChange={this.changeHandler} /> */}
                <ChromePicker color={background_color} onChange={this.bgColorPickerHandler.bind(this)} />
              </Col>
              <Col>
                <Form.Label>Button Color:  <span className="asterisk"> *</span></Form.Label>
                {/* <Form.Control type="text" min="1" placeholder="Button Color" name="button_color" value={button_color} onChange={this.changeHandler} /> */}
                <ChromePicker color={button_color} onChange={this.btnColorPickerHandler.bind(this)} />
              </Col>
              <Col>
                <Form.Label>Button Text Color:  <span className="asterisk"> *</span></Form.Label>
                {/* <Form.Control type="text" min="1" placeholder="Button Text Color" name="button_text_color" value={button_text_color} onChange={this.changeHandler} /> */}
                <ChromePicker color={button_text_color} onChange={this.btnTxtColorPickerHandler.bind(this)} />
              </Col>
              <Col>
                <Form.Label>Text Color:  <span className="asterisk"> *</span></Form.Label>
                {/* <Form.Control type="text" min="1" placeholder="Text Color" name="text_color" value={text_color} onChange={this.changeHandler} /> */}
                <ChromePicker color={text_color} onChange={this.txtColorPickerHandler.bind(this)} />
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>Button Title:  <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" min="1" placeholder="Button Title" name="button_title" value={button_title} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Sequence: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="number" min="1" placeholder="Sequence" name="sequence" value={sequence} onChange={this.changeHandler} />
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Row>

              <Col>
                <Form.Label>Button Icon Upload: <span className="asterisk"> *</span></Form.Label><br></br>
                {this.state.BannerButtonView ?
                  <Figure>
                    <Figure.Image src={this.state.BannerButtonView} thumbnail width={171} height={180} />
                  </Figure> : <p>No image selected</p>}
                <Form.File
                  name="button_icon"
                  label="Button Icon Upload"
                  accept="image/*"
                  onChange={this.onBannerButtonIconChange}
                  custom
                />
              </Col>
              <Col>
                <Form.Label>Background Image Upload: <span className="asterisk"> *</span></Form.Label><br></br>
                {this.state.bannerBackgroundImageView ?
                  <Figure>
                    <Figure.Image src={this.state.bannerBackgroundImageView} thumbnail width={171} height={180} />
                  </Figure> : <p>No image selected</p>}
                <Form.File
                  name="background_image"
                  label="Background Image Upload"
                  accept="image/*"
                  onChange={this.onBannerBackgroundImageChange}
                  custom
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Row>
              <Col>

                <Form.Label>Platform:  <span className="asterisk"> *</span></Form.Label>
                <Form.Control as="select" name="platform_slug" onChange={this.changeHandler}>
                  <option defaultValue value="">Select Platfom</option>
                  {
                    platforms.map((data, index) =>
                      <option key={index} value={data.uniqueSlug}>{data.name}</option>
                    )}
                </Form.Control>
              </Col>
              <Col>
                <Form.Label>Badge: <span className="asterisk"> *</span></Form.Label>
                <Form.Control as="select" name="badge_slug" onChange={this.changeHandler}>
                  <option defaultValue value="">Select Badge</option>
                  {
                    badges.map((data, index) =>
                      <option key={index} value={data.uniqueSlug}>{data.name}</option>
                    )}
                </Form.Control>
              </Col>
              <Col>
                <Form.Label>Tags: <span className="asterisk"> *</span></Form.Label>
                {/* <Form.Control as="select" name="tag_slug" onChange={this.changeHandler}>
                  <option defaultValue value="">Select Tags</option>
                  {
                    tags.map((data, index) =>
                      <option key={index} value={data.uniqueSlug}>{data.name}</option>
                    )}
                </Form.Control> */}
                <Select isMulti isSearchable onChange={this.tagsSelectHandler} name="tag_slug" placeholder="Select Tags" options={this.state.tags} />
              </Col>
              <Col>
                <Form.Label>Banner Action:  <span className="asterisk"> *</span></Form.Label>
                <Form.Control as="select" name="action_slug" onChange={this.changeHandler}>
                  <option defaultValue value="">Select Action</option>
                  {
                    action.map((data, index) =>
                      <option key={index} value={data.uniqueSlug}>{data.name}</option>
                    )
                  }
                </Form.Control>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>Action Content: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" placeholder="Action Content" name="action_content" value={action_content} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Content Slug: <span className="asterisk"> *</span></Form.Label>
                <Form.Control type="text" placeholder="Action Content" name="content_slug" value={content_slug} onChange={this.changeHandler} />
              </Col>
              <Col>
                <Form.Label>Language:  <span className="asterisk"> *</span></Form.Label>
                <Form.Control as="select" name="language" value={language} onChange={this.changeHandler}>
                  <option defaultValue value="">Select Language</option>
                  {
                    languages.map((data, index) =>
                      <option key={index} value={data.name}>{data.name}</option>
                    )
                  }
                </Form.Control>
              </Col>
            </Form.Row>
          </Form.Group>

          <Button type="submit" variant="info">Publish</Button>
        </Form>
      </div>
    )
  }
}

export default AddBanner

import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';

import { Nav, Navbar, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import './Main.css';


 class NavMenu extends Component {
     constructor(props){
        super(props)
     }
    state = { 
        LoggedIn : false
     }
    logout = () => {
        localStorage.removeItem('LoggedIn')
        this.props.history.push('/')
    }
    componentDidMount(){
       let loginCheck = localStorage.getItem('LoggedIn')
        if (loginCheck){
            this.setState ({
                LoggedIn : true
            })
        }
        else{
            this.setState ({
                LoggedIn : false
            })
        }
    }


    render() { 
        return (
            <React.Fragment>
               <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">

                        {/* <Nav.Link >
                            <Link to="/users">Users</Link>
                        </Nav.Link> */}
                        {/* <NavDropdown title="Add New" id="basic-nav-dropdown">
                            <NavDropdown.Item><Link to="/channel">Channel</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to="/shows">Shows</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to="/category">Category</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to="/banner">Banner</Link></NavDropdown.Item>
                            <NavDropdown.Item><Link to="/pages">Pages</Link></NavDropdown.Item>
                        </NavDropdown>                       */}
                    </Nav>
                    {/* <Form inline>
                        <FormControl type="text" placeholder="Search" className="mr-sm-3" />
                        <Button  className="mr-sm-3" variant="outline-light">Search</Button>
                    </Form> */}
                  
                        <Button onClick={this.logout} >
                        Logout
                    </Button>
                    
                    
                </Navbar.Collapse>
            </React.Fragment>
         );
    }
}

export default withRouter(NavMenu);
import React, { Component } from 'react';
import { Table, Button, Modal, Form, Col, Image } from 'react-bootstrap';
import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { getAllLanguages, removeLanguages } from '../../../services/serviceLanguages';
import AddLanguage from './AddLanguage';
import auth from '../../../services/authentication';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class AllLanguages extends Component {


    constructor(props) {
        super(props)

        this.state = {

            Language: [],
            isLoading: false,
            isEdit: false,
        }
    }

    hideLoader = () => {
        this.setState({ isLoading: false });
    }

    showLoader = () => {
        this.setState({ isLoading: true });
    }



    componentDidMount() {
        this.showLoader();
        this.fetchLanguageData()
    }



    async onDelete(uniqueSlug, name) {
        await removeLanguages(uniqueSlug)
            .then(response => {
                if (response.status === 204) {
                    toast.success(`Language ${name} deleted successfully.`);
                    this.componentDidMount();
                }
            })
            .catch(err => {
                console.log(err)
                toast.error(`Language ${name} delete failed, try again later.`);
            });
    }

    handleClose = () => {
        this.setState({ isEdit: false })
    };
    handleShow = () => {
        this.setState({ isEdit: true });
    }

    toggleEdit = show => {
        this.setState({
            editedLang: show,
            isEdit: !this.isEdit
        })
    }


    fetchLanguageData = () => {
        getAllLanguages()
            .then(response => {
                console.log(response.data.results)
                this.setState({ Language: response.data.results })
                this.hideLoader();
            })
            .catch(error => {
                console.log(error)
            });
    }


    render() {

        const { Language } = this.state
        return (

            <div>
                <ToastContainer position="top-center" />
                {(this.state.isEdit) ?
                    <Modal size="xl" show={this.state.isEdit} onHide={this.handleClose}>
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Badge</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <AddLanguage data={this.state.editedLang} Edit={this.state.isEdit} toggleEdit={this.handleClose} />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleClose}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    : null
                }
                <h2>List of Language</h2>
                {(this.state.isLoading) ? <Loader type="ThreeDots" color="#eb1163" height={100} width={50} /> :
                    <Table responsive hover>
                        <thead>
                            <tr>
                                <th scope="col">Sr.no</th>
                                <th scope="col">Language Name</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Language.length ?
                                    Language.map((badge, index) =>
                                        <tr key={index}>
                                            <td key={index}>{index + 1}</td>
                                            <td>{badge.name}</td>
                                            <td>
                                                <Button variant="primary" onClick={() => this.toggleEdit(badge)}>Edit</Button>
                                                <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete badge ${badge.name}?`)) this.onDelete(badge.uniqueSlug, badge.name) }}>Delete</Button>
                                            </td>
                                        </tr>
                                    ) :

                                    null
                            }
                        </tbody>
                    </Table>
                }


            </div >
        )
    }
}

export default AllLanguages

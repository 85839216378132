import React, { Component } from "react";
import { Form, Table, Button, Container, Row, Col } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import * as Icons from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import auth from "../../../services/authentication";
import {
  getAll,
  removeSection,
  createSection,
  baseURL,
  getAllSectionsPagination,
} from "../../../services/serviceSections";
import { getChannelDropdown } from "../../../services/serviceChannels";
import {
  getEpisode,
  getAllepisodeDropdown,
  getAllepisode,
  allEpisodeDropdownPagination,
  liveEpisodes,
} from "../../../services/serviceEpisodes";
import update from "immutability-helper";
import axios from "axios";
import { ChromePicker } from "react-color";
import { getAllSectionTypes } from "../../../services/serviceSectionTypes";
import {
  getShowsDropDowns,
  getAllShow,
  getShow,
} from "../../../services/serviceShows";
import { getCategoryDropDowns } from "../../../services/serviceCategories";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { allLiveShowDropdown } from "../../../services/serviceLive";

class AllSection extends Component {
  // editedSectionContent = []

  state = {
    next: "",
    totalSections: 0,
    paginationCount: 0,
    currentPage: 1,
    options: [],
    episodeOptions: [],
    sections: [],
    sectionTypes: [],
    editedSection: {},
    uniqueSlug: "",
    Items: [],
    Type: "",
    showdata: [],
    epidata: [],
    thumbnail_url: "",
    short_url: "",
    isLoading: false,
    isShowLoading: true,
    addNewSection: false,
  };

  addDefaultContent = (items) => {
    if (!items) return [];
    let content = items;
    let newContent = [];
    content.forEach((data) => {
      newContent.push({
        value: data.uniqueSlug,
        label: data.title || data.name,
      });
    });
    return newContent;
  };
  getTypeApi(type) {
    this.setState({ Type: type });
    this.setState({ isShowLoading: true });
    switch (type) {
      //channel
      case "set-304ebf":
        getChannelDropdown()
          .then((res) => {
            let channels = res.data;
            let channelOptions = [];
            channels.forEach((data) => {
              channelOptions.push({
                value: data.uniqueSlug,
                label: data.title,
              });
            });
            this.setState({
              ChannelOptions: channelOptions,
              isShowLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("channel data fetch failed");
          });
        break;
      //Category
      case "set-c33acd":
        getCategoryDropDowns()
          .then((res) => {
            let categories = res.data;
            let categoriesOptions = [];
            categories.forEach((data) => {
              categoriesOptions.push({
                value: data.uniqueSlug,
                label: data.name,
              });
            });
            this.setState({
              categoriesOptions: categoriesOptions,
              isShowLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Category data fetch failed");
          });
        break;
      case "set-4a137b":
        // episode
        getShowsDropDowns()
          .then((res) => {
            let categories = res.data;
            let showOptions = [];
            categories.forEach((data) => {
              showOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ Showoptions: showOptions, isShowLoading: false });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Show data fetch failed");
          });
        // this.loadOptions()
        break;
      case "set-20f9be":
        // Live Shows
        allLiveShowDropdown()
          .then((res) => {
            let LiveShow = res.data;
            let LiveshowOptions = [];
            LiveShow.forEach((data) => {
              LiveshowOptions.push({
                value: data.uniqueSlug,
                label: data.title,
              });
            });
            this.setState({
              LiveshowOptions: LiveshowOptions,
              isShowLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Live_Show data fetch failed");
          });
        break;
      case "set-abe2e6":
        // Live Episode
        liveEpisodes()
          .then((res) => {
            let LiveEpisode = res.data.results;
            let LiveEpisodeOptions = [];
            LiveEpisode.forEach((data) => {
              LiveEpisodeOptions.push({
                value: data.uniqueSlug,
                label: data.title,
              });
            });
            this.setState({
              LiveEpisodeOptions: LiveEpisodeOptions,
              isShowLoading: false,
            });
          })
          .catch((err) => {
            console.log(err);
            toast.error("Live Episode data fetch failed");
          });
        break;
      default:
        getShowsDropDowns()
          .then((response) => {
            let shows = response.data;
            let showOptions = [];
            shows.forEach((data) => {
              showOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ showOptions: showOptions, isShowLoading: false });
          })
          .catch((err) => alert("Couldn't fetch shows data"));
    }
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };
  // for all section
  fetchSectionData = async (pages) => {
    // this.setState({ editedSection: {} })
    this.showLoader();
    await getAllSectionsPagination(pages)
      .then((response) => {
        this.setState({
          sections: response.data.results,
          next: response.data.next,
          paginationCount: Math.ceil(response.data.count / 10),
          Items: response.data.results.items,
          totalSections: response.data.results.length,
          isLoading: false,
          editedSection: {},
        });
      })
      .catch((err) => {
        this.hideLoader();
        toast.error("Couldn't fetch Sections");
      });
  };

  componentDidMount() {
    this.showLoader();
    this.fetchSectionData();
    getAllSectionTypes()
      .then((response) => {
        this.setState({
          sectionTypes: response.data.results,
          isShowLoading: false,
        });
      })
      .catch((err) => {
        this.hideLoader();
        toast.error("Couldn't fetch section types");
      });
  }

  deleteSectionHandler = (slug, section) => {
    this.showLoader();
    removeSection(slug)
      .then(() => {
        toast.success(`Show "${section}" deleted successfully!`);
        this.fetchSectionData(this.state.currentPage);
      })
      .catch((err) => {
        toast.error(
          `Section "${section}" delete unsuccessful, please try again later!`
        );
        this.hideLoader();
      });
  };

  toggleSectionEdit = (slug, section) => {
    this.getTypeApi(section.sectionType.uniqueSlug);
    if (
      this.state.editedSection.dirty &&
      window.confirm(
        `There are unsaved values in current section "${this.state.editedSection.name}", Do you want to proceed without saving? updated values will be lost!`
      )
    ) {
      if (this.state.addNewSection) {
        let newSection = this.state.sections;
        newSection.shift();
        this.toggleAddNewSectionHandler();
        this.setState({ sections: newSection });
      }
      this.setState({
        uniqueSlug: slug,
        editedSection: {
          name: section.name,
          color: section.color,
          subtitle: section.subtitle,
          sequence: section.sequence,
          sectionType: section.sectionType,
          thumbnail_url: section.thumbnail_url,
          short_url: section.short_url,
          childSlugs: section.childSlugs,
          content_slug: this.addDefaultContent(section.items),
          dirty: false,
        },
      });
    }
    if (!this.state.uniqueSlug || !this.state.editedSection.dirty) {
      this.setState({
        uniqueSlug: slug,
        editedSection: {
          name: section.name,
          color: section.color,
          subtitle: section.subtitle,
          sequence: section.sequence,
          sectionType: section.sectionType,
          thumbnail_url: section.thumbnail_url,
          short_url: section.short_url,
          childSlugs: section.childSlugs,
          content_slug: this.addDefaultContent(section.items),
          dirty: false,
        },
      });
    }
  };

  cancelSectionHandler = () => {
    this.setState({ editedSection: {}, uniqueSlug: "" });
  };

  editSectionHandler = (event) => {
    if (event.target.name === "section_type_slug") {
      this.getTypeApi(event.target.value);
    }
    let prevEditedSection = this.state.editedSection;
    prevEditedSection.dirty = true;
    let newEditedSection = update(
      prevEditedSection,
      event.target.name === "sequence"
        ? { [event.target.name]: { $set: parseInt(event.target.value) } }
        : // event.target.name === "thumbnail_url" && event.target.value == "" ?
          //   { [event.target.name]: { $set: "" } } :
          { [event.target.name]: { $set: event.target.value } }
    );
    this.setState({ editedSection: newEditedSection });
  };

  updateSectionHandler = () => {
    this.showLoader();
    let contentSlug = this.state.editedSection.content_slug.map((data) => {
      return data.value;
    });
    let content_slug = contentSlug.join();
    let childSlugValue = JSON.stringify(this.state.editedSection.childSlugs);
    let childSlugs = childSlugValue;
    let data = this.state.editedSection;
    let formData = new FormData();
    for (let [key, value] of Object.entries(data)) {
      switch (key) {
        case "dirty":
        case "sectionType":
        case "thumbnail_url":
          if (key === "thumbnail_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "thumbnail_url" && value !== "") {
            formData.append(`${key}`, value);
          }
          break;
        case "short_url":
          if (key === "short_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "short_url" && value !== "") {
            formData.append(`${key}`, value);
          }
          break;
        case "content_slug":
          formData.append(`${key}`, content_slug);
          break;
        case "childSlugs":
          formData.append(`${key}`, childSlugs);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }

    axios({
      method: "PATCH",
      url: `${baseURL}/section/${this.state.uniqueSlug}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      auth: auth,
      data: formData,
    })
      .then(() => {
        toast.success(`Section "${this.state.editedSection.name}" updated!`);
        this.setState({ editedSection: {}, uniqueSlug: "" }, () =>
          this.fetchSectionData(this.state.currentPage)
        );
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };

  colorPickerHandler = (color) => {
    let newSection = this.state.editedSection;
    newSection.color = color.hex;
    newSection.dirty = true;
    this.setState({ editedSection: newSection });
  };

  toggleAddNewSectionHandler = () => {
    this.setState({ addNewSection: !this.state.addNewSection });
  };

  addNewSectionHandler = () => {
    let prevSection = this.state.sections;
    prevSection.unshift({
      name: "",
      color: "",
      subtitle: "",
      thumbnail_url: "",
      short_url: "",
      sequence: "",
      sectionType: "",
      content_slug: [],
      childSlugs: [],
      dirty: false,
    });
    this.toggleAddNewSectionHandler();
    this.setState({ sections: prevSection, uniqueSlug: "" });
  };

  selectHandler = (value) => {
    let prevEditedSection = this.state.editedSection;
    prevEditedSection.dirty = true;
    let newEditedSection = update(prevEditedSection, {
      content_slug: { $set: value },
    });
    this.setState({ editedSection: newEditedSection });
  };

  cancelNewSectionHandler = () => {
    let prevSection = this.state.sections;
    prevSection.shift();
    this.toggleAddNewSectionHandler();
    this.setState({ sections: prevSection });
  };

  postNewSectionHandler = () => {
    let contentSlug = this.state.editedSection.content_slug.map((data) => {
      return data.value;
    });
    let content_slug = contentSlug.join();
    let childSlugValue = JSON.stringify(this.state.editedSection.childSlugs);
    let childSlugs = childSlugValue;
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state.editedSection)) {
      switch (key) {
        case "dirty":
        case "sectionType":
          break;
        case "content_slug":
          formData.append(`${key}`, content_slug);
          break;
        case "thumbnail_url":
          if (key === "thumbnail_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "thumbnail_url" && value != "") {
            formData.append(`${key}`, value);
          }
          break;
        case "short_url":
          if (key === "short_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "short_url" && value !== "") {
            formData.append(`${key}`, value);
          }
          break;
        case "childSlugs":
          formData.append(`${key}`, childSlugs);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createSection(formData)
      .then((response) => {
        toast.success(
          `New section "${response.data.name}" created succesfully`
        );
        // this.setState({ editedSection: {} }, () => {
        this.toggleAddNewSectionHandler();
        this.fetchSectionData(this.state.currentPage);
        // })
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.hideLoader();
        }
        console.log(error);
      });
  };
  updateData = async () => {
    this.fetchSectionData(this.state.currentPage);
    this.addNewSectionHandler();
  };
  changeEpisode = (value) => {
    let Selectedvalue = value;
    let Slug =
      value !== null && value.length !== 0
        ? value[Selectedvalue.length - 1].value
        : null;
    if (Slug !== null) {
      getShow(Slug)
        .then((response) => {
          let data = response.data.episodes;
          if (data.length > 0) {
            let showOptions = [];
            data.forEach((data) => {
              showOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ options: showOptions, isShowLoading: false });
          } else {
            this.setState({ data: [{ title: "No Data" }], isLoading: false });
          }
        })

        .catch((err) => alert("Couldn't fetch episode data"));
      let prevEditedSection = this.state.editedSection;
      prevEditedSection.dirty = true;
      let newEditedSection = update(prevEditedSection, {
        childSlugs: { $set: value },
      });
      this.setState({ editedSection: newEditedSection });
    } else {
      console.log("cannot get episodes");
    }
  };
  handlePageClick = (data) => {
    // this.showLoader()
    let selected = data.selected + 1;
    this.props.history.replace("?page=" + selected);
    this.setState({ currentPage: selected }, () =>
      this.fetchSectionData(this.state.currentPage)
    );
  };
  render() {
    const {
      sections,
      editedSection,
      sectionTypes,
      epidata,
      content_slug,
      show_val,
      showdata,
    } = this.state;
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );
    return (
      <>
        <ToastContainer position="top-right" />
        <div>
          <Row>
            <Col>
              <h2>Sections list ({this.state.totalSections})</h2>
              <p>(Double click on a row to edit)</p>
            </Col>
          </Row>
          <Row>
            <Col>
              {!this.state.addNewSection ? (
                <Button
                  variant="outline-success"
                  title="Add New Show"
                  onClick={this.addNewSectionHandler}
                >
                  <Icons.Plus />
                </Button>
              ) : null}{" "}
              &nbsp;
              <Button
                variant="outline-info"
                title="Refresh List"
                onClick={this.updateData}
              >
                <Icons.ArrowClockwise />
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table responsive hover>
                <thead>
                  <tr key="header">
                    <th scope="col">Sr.no</th>
                    <th scope="col">
                      Name<span className="asterisk">*</span>
                    </th>
                    <th scope="col">Subtitle</th>
                    <th scope="col">Color</th>
                    <th scope="col">Thumbnail Url</th>
                    <th scope="col">
                      Short Url<span className="asterisk">*</span>
                    </th>
                    <th scope="col">Sequence</th>
                    <th scope="col">
                      Type<span className="asterisk">*</span>
                    </th>
                    <th scope="col">
                      Content<span className="asterisk">*</span>
                    </th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {!this.state.isLoading ? (
                    sections.map((section, index) =>
                      this.state.uniqueSlug === section.uniqueSlug ? (
                        <tr key={index} style={{ backgroundColor: "#ededed" }}>
                          <td>{index + 1}</td>
                          <td>
                            <input
                              type="text"
                              name="name"
                              value={editedSection.name}
                              onChange={this.editSectionHandler.bind(this)}
                              placeholder="Enter name"
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="subtitle"
                              value={
                                editedSection.subtitle
                                  ? editedSection.subtitle
                                  : ""
                              }
                              placeholder="Enter subtitle"
                              onChange={this.editSectionHandler.bind(this)}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <ChromePicker
                              color={
                                editedSection.color
                                  ? editedSection.color
                                  : "#fffff"
                              }
                              onChange={this.colorPickerHandler.bind(this)}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              name="thumbnail_url"
                              value={editedSection.thumbnail_url}
                              onChange={this.editSectionHandler.bind(this)}
                              placeholder="Enter Thumbnai URL here"
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              placeholder="Enter Short URL here"
                              name="short_url"
                              value={editedSection.short_url}
                              onChange={this.editSectionHandler.bind(this)}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              type="number"
                              min="1"
                              name="sequence"
                              value={
                                editedSection.sequence
                                  ? editedSection.sequence
                                  : 1
                              }
                              onChange={this.editSectionHandler.bind(this)}
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <select
                              name="section_type_slug"
                              onChange={this.editSectionHandler.bind(this)}
                            >
                              <option
                                defaultValue
                                value={
                                  editedSection.sectionType
                                    ? editedSection.sectionType.uniqueSlug
                                    : ""
                                }
                              >
                                {editedSection.sectionType
                                  ? editedSection.sectionType.name
                                  : "Select type"}
                              </option>
                              {sectionTypes.map((sectionType, index) => (
                                <option
                                  key={index}
                                  value={
                                    sectionType.uniqueSlug
                                      ? sectionType.uniqueSlug
                                      : ""
                                  }
                                >
                                  {sectionType.name ? sectionType.name : "NA"}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Episode */}
                          {this.state.Type === "set-4a137b" ||
                          this.state.editedSection.sectionType.uniqueSlug ===
                            "set-4a137b" ? (
                            <>
                              <Form.Label>show name</Form.Label>

                              <Select
                                isMulti
                                defaultValue={
                                  this.state.editedSection.childSlugs
                                }
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    width: 300,
                                  }),
                                }}
                                name="childSlugs"
                                onChange={this.changeEpisode}
                                isLoading={this.state.isShowLoading}
                                options={this.state.Showoptions}
                              />
                              <p>Episode</p>

                              <Select
                                isMulti
                                defaultValue={
                                  this.state.editedSection.content_slug
                                }
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    width: 300,
                                  }),
                                }}
                                name="content_slug"
                                onChange={this.selectHandler}
                                isLoading={this.state.isShowLoading}
                                options={this.state.options}
                              />
                            </>
                          ) : // channel
                          this.state.Type === "set-304ebf" ||
                            this.state.editedSection.sectionType.uniqueSlug ===
                              "set-304ebf" ? (
                            <Select
                              isMulti
                              defaultValue={
                                this.state.editedSection.content_slug
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  width: 300,
                                }),
                              }}
                              name="content_slug"
                              onChange={this.selectHandler}
                              isLoading={this.state.isShowLoading}
                              options={this.state.ChannelOptions}
                            />
                          ) : // category
                          this.state.Type === "set-c33acd" ||
                            this.state.editedSection.sectionType.uniqueSlug ===
                              "set-c33acd" ? (
                            <Select
                              isMulti
                              defaultValue={
                                this.state.editedSection.content_slug
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  width: 300,
                                }),
                              }}
                              name="content_slug"
                              onChange={this.selectHandler}
                              isLoading={this.state.isShowLoading}
                              options={this.state.categoriesOptions}
                            />
                          ) : // show
                          this.state.Type === "set-1fc08c" ||
                            this.state.editedSection.sectionType.uniqueSlug ===
                              "set-1fc08c" ? (
                            <Select
                              isMulti
                              defaultValue={
                                this.state.editedSection.content_slug
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  width: 300,
                                }),
                              }}
                              name="content_slug"
                              onChange={this.selectHandler}
                              isLoading={this.state.isShowLoading}
                              options={this.state.showOptions}
                            />
                          ) : //  LiveShows
                          this.state.Type === "set-20f9be" ||
                            this.state.editedSection.sectionType.uniqueSlug ===
                              "set-20f9be" ? (
                            <Select
                              isMulti
                              defaultValue={
                                this.state.editedSection.content_slug
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  width: 300,
                                }),
                              }}
                              name="content_slug"
                              onChange={this.selectHandler}
                              isLoading={this.state.isShowLoading}
                              options={this.state.LiveshowOptions}
                            />
                          ) : // LiveEpisode
                          this.state.Type === "set-abe2e6" ||
                            this.state.editedSection.sectionType.uniqueSlug ===
                              "set-a72505" ? (
                            <Select
                              isMulti
                              defaultValue={
                                this.state.editedSection.content_slug
                              }
                              styles={{
                                control: (styles) => ({
                                  ...styles,
                                  width: 300,
                                }),
                              }}
                              name="content_slug"
                              onChange={this.selectHandler}
                              isLoading={this.state.isShowLoading}
                              options={this.state.LiveEpisodeOptions}
                            />
                          ) : (
                            <input
                              type="text"
                              placeholder="Select Section Type"
                              disabled
                            />
                          )}

                          <td>
                            {" "}
                            {this.state.uniqueSlug ? (
                              <div>
                                <Button
                                  variant="outline-success"
                                  onClick={this.updateSectionHandler}
                                >
                                  Update
                                </Button>
                                &nbsp;
                                <Button
                                  variant="outline-warning"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        "Do you wish to cancel ongoing changes?"
                                      )
                                    )
                                      this.cancelSectionHandler();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            ) : (
                              <div>
                                <Button
                                  variant="outline-success"
                                  onClick={this.postNewSectionHandler}
                                >
                                  Add
                                </Button>
                                <Button
                                  variant="outline-danger"
                                  onClick={() => {
                                    if (
                                      window.confirm(
                                        `Cancel adding new section "${section.name}"?`
                                      )
                                    )
                                      this.cancelNewSectionHandler();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            )}
                          </td>
                          {/* <td> {this.state.uniqueSlug? <div><Button variant="outline-success" onClick={this.updateSectionHandler}>Update</Button>&nbsp;<Button variant="outline-warning" onClick={() => { if (window.confirm("Do you wish to cancel ongoing changes?")) this.cancelSectionHandler() }}>Cancel</Button></div>:<div>null</div>}</td> */}
                        </tr>
                      ) : (
                        <tr
                          key={index}
                          onDoubleClick={this.toggleSectionEdit.bind(
                            this,
                            section.uniqueSlug,
                            section
                          )}
                        >
                          <td>{index + 1}</td>
                          <td>
                            {section.name ? (
                              section.name
                            ) : (
                              <input
                                type="text"
                                placeholder="Enter Show Name"
                                disabled
                              />
                            )}
                          </td>
                          <td>
                            {section.subtitle ? (
                              section.subtitle
                            ) : (
                              <input
                                type="text"
                                placeholder="Enter Show Subtitle"
                                disabled
                              />
                            )}
                          </td>
                          <td>
                            <svg width="20" height="20">
                              <rect
                                width="20"
                                height="20"
                                style={{
                                  fill: section.color,
                                  strokeWidth: 3,
                                  stroke: "rgb(0,0,0)",
                                }}
                              />
                            </svg>
                          </td>
                          <td>
                            {section.thumbnailUrl ? (
                              <input
                                type="text"
                                placeholder="Enter Thumbnail URL here"
                                name="thumbnail_url"
                                value={section.thumbnailUrl}
                                disabled
                              />
                            ) : null}
                          </td>
                          <td>
                            {section.shortUrl ? (
                              <input
                                type="text"
                                placeholder="Enter Short URL here"
                                name="short_url"
                                value={section.shortUrl}
                                disabled
                              />
                            ) : null}
                          </td>
                          <td>
                            {section.sequence ? (
                              section.sequence
                            ) : (
                              <input type="number" value="1" disabled />
                            )}
                          </td>
                          <td>
                            {section.sectionType ? (
                              section.sectionType.name
                            ) : (
                              <input
                                type="text"
                                placeholder="Select Section Type"
                                disabled
                              />
                            )}
                          </td>
                          <td>
                            {this.state.addNewSection && !section.uniqueSlug ? (
                              <input
                                type="text"
                                placeholder="No Content "
                                disabled
                              />
                            ) : section.items.length === 0 ? (
                              0
                            ) : (
                              section.items.length
                            )}
                          </td>
                          <td>
                            {this.state.addNewSection && !section.uniqueSlug ? (
                              <Button
                                variant="outline-danger"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `Cancel adding new section "${section.name}"?`
                                    )
                                  )
                                    this.cancelNewSectionHandler();
                                }}
                              >
                                Cancel
                              </Button>
                            ) : (
                              <Button
                                variant="outline-danger"
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      `Are you sure you want to delete section "${section.name}"?`
                                    )
                                  )
                                    this.deleteSectionHandler(
                                      section.uniqueSlug,
                                      section.name
                                    );
                                }}
                              >
                                Delete
                              </Button>
                            )}
                          </td>
                        </tr>
                      )
                    )
                  ) : (
                    <tr>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                      <td>{loader}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
        {this.state.count === 0 ? (
          <p>Please wait...</p>
        ) : (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={this.state.paginationCount}
            onPageChange={this.handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={this.state.currentPage - 1}
          ></ReactPaginate>
        )}
      </>
    );
  }
}

export default AllSection;

import http from "../http-common";
import auth from "./authentication";

const baseURL = "https://preprod.aawaz.com";
const GetAllProducts = () => {
  return http.get(`${baseURL}/subs/cms/products/`, { auth: auth });
};

const GetProductById = (id) => {
  return http.get(`${baseURL}/subs/cms/products/${id}`, { auth: auth });
};
const createProducts = (data) => {
  return http.post(`${baseURL}/subs/cms/products/`, data, { auth: auth });
};

const updateProducts = (id, data) => {
  return http.patch(`${baseURL}/subs/cms/products/${id}/`, data, {
    auth: auth,
  });
};

const removeProducts = (id) => {
  return http.delete(`${baseURL}/subs/cms/products/${id}`, { auth: auth });
};

const CreatePlans = (data) => {
  return http.post(`${baseURL}/subs/cms/plans/`, data, { auth: auth });
};
const UpdatePlans = (id, data) => {
  return http.patch(`${baseURL}/subs/cms/plans/${id}/`, data, { auth: auth });
};
const deletePlans = (id) => {
  return http.delete(`${baseURL}/subs/cms/plans/${id}/`, { auth: auth });
};

export {
  GetAllProducts,
  GetProductById,
  createProducts,
  updateProducts,
  removeProducts,
  CreatePlans,
  UpdatePlans,
  deletePlans,
};

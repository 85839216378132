import React, { Component } from 'react'
import { Table, Button, Modal, Form, Col, Figure, ListGroup, Container, Row } from 'react-bootstrap';
import { getAll, removeSection, createSection, baseURL, getAllSectionsPagination, sectionSequnce } from '../../../services/serviceSections';
import { ToastContainer, toast } from 'react-toastify';
import ContentLoader from "react-content-loader"
import 'react-toastify/dist/ReactToastify.css';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';

export default class SequeceSection extends Component {

    state = {
        section: [],
        isLoading: true,
        isSequenceDisabled: true
    }
    componentDidMount() {
        this.getSectionData()
    }
    getSectionData = async () => {
        await getAll()
            .then((res) => {
                console.log(res.data)
                this.setState({ section: res.data, totalCategory: res.data.length, isLoading: false, })
            })
            .catch((err) => {
                console.log(err)
            })
    }
    handleRLDDChange = (newItems) => {
        let newArr = newItems
        let newEpi = newArr.map((item, index) => {
            return { ...item, sequence: index + 1 }
        })
        this.setState({ section: newEpi, isSequenceDisabled: false });
    }
    toggleEdit = (id) => {
        this.props.history.push(`/section/edit-section/${id}`)
        // this.setState({
        //   editedCategory: show,
        //   isEdit: !this.isEdit
        // })
    }
    deleteSectionHandler = (slug, section) => {
        // this.showLoader()
        removeSection(slug)
            .then(() => {
                toast.success(`Show "${section}" deleted successfully!`)
                this.getSectionData()
            })
            .catch(err => {
                toast.error(`Section "${section}" delete unsuccessful, please try again later!`)
                this.hideLoader()
            })
    }
    saveSequence = () => {
        let epiSlugs = this.state.section.map(item => item.uniqueSlug)
        console.log(epiSlugs)
        sectionSequnce(epiSlugs)
            .then(() => {
                this.setState({ isSequenceDisabled: true }, () => toast.success('Sequence updated successfully!'))
            })
            .catch(err => {
                toast.error("Couldn't update the sequence at the moment!")
                console.log('sectionSequnce sequence error: ', err)
            })
    }
    render() {
        const loader = <ContentLoader backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>
        return (
            <div>
                <ToastContainer />
                <h2>Section list ({this.state.totalCategory})   <Button variant="primary" disabled={this.state.isSequenceDisabled} onClick={() => this.saveSequence()}>Save Sequence</Button></h2>

                <ListGroup>
                    <ListGroup.Item variant="secondary">
                        <Container fluid>
                            <Row style={{ textAlign: 'center' }}>
                                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                    <strong>Sr.no</strong>
                                </Col>
                                <Col className>
                                    <strong>Section Name</strong>
                                </Col>
                                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                    <strong>Seq No.</strong>
                                </Col>
                                <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                    <strong>Actions</strong>
                                </Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                    {!this.state.isLoading ?
                        <RLDD
                            id
                            items={this.state.section}
                            itemRenderer={(data) => {
                                return (

                                    <ListGroup.Item action>
                                        <Container fluid>
                                            <Row style={{ textAlign: 'center' }}>
                                                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                                    <strong>{data.id}</strong>
                                                </Col>
                                                <Col>
                                                    <strong>{data.name}</strong>
                                                </Col>
                                                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                                    <strong>{data.sequence}</strong>
                                                </Col>
                                                <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                                    <Button variant="primary" onClick={() => this.toggleEdit(data.uniqueSlug)}>Edit</Button>
                                                    <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete category ${data.name}?`)) this.deleteSectionHandler(data.uniqueSlug, data.name) }}>Delete</Button>
                                                </Col>
                                            </Row>
                                        </Container>

                                    </ListGroup.Item>
                                );
                            }}
                            onChange={this.handleRLDDChange}
                        />
                        :
                        <Row style={{ textAlign: 'center' }}>
                            <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                {loader}
                            </Col>
                            <Col>
                                {loader}
                            </Col>
                            <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                                {loader}
                            </Col>
                            <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                                {loader}
                            </Col>

                        </Row>}

                </ListGroup>

            </div>
        )
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Table, Button, Alert, Container, Row, Col, Image, Modal, Form } from 'react-bootstrap';
import ContentLoader from "react-content-loader"
import queryString from 'query-string'
import { getAllShowPaginate, removeShow, getShowsDropDowns } from '../../../services/serviceShows';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import NewShow from './NewShow'
import 'react-toastify/dist/ReactToastify.css';

class AllShows extends Component {


  constructor(props) {
    super(props)

    this.state = {
      next: "not null",
      totalShow: 0,
      paginationCount: 0,
      currentPage: 1,
      shows: [],
      isLoading: false,
      editshows: false,
      filter: "True",
    }
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  }

  showLoader = () => {
    this.setState({ isLoading: true });
  }

  fetchData = async () => {
    this.showLoader();
    await getShowsDropDowns().then(response => {
      // console.log(response.data.results)
      // localStorage.setItem("shows", JSON.stringify(response.data.results))
      this.setState({ shows: response.data })
      this.hideLoader();
    })
      .catch(error => {
        toast.error("Error occured while fetching data")
        console.log(error)
        this.hideLoader();
      });
  }

  async componentDidMount() {
    // const values = queryString.parse(this.props.location.search)
    // values.page? this.setState({currentPage: parseInt(values.page)}, ()=> this.fetchData(this.state.currentPage)): this.fetchData()
    this.fetchData()
  }


  async onDelete(uniqueSlug, title) {
    // this.toggleEdit(uniqueSlug)
    await removeShow(uniqueSlug)
      .then(response => {
        if (response.status === 204) {
          toast(`Show ${title} deleted successfully.`);
          this.fetchData()
          // if (this.state.next === null && this.state.shows.length < 2 && this.state.currentPage !== 1) {
          //   this.setState({ currentPage: this.state.currentPage - 1 }, () => this.fetchData(this.state.currentPage))
          // }
          // else {
          //   this.fetchData(this.state.currentPage)
          // }
        }
      })
      .catch(err => {
        toast.error(`Show ${title} deleted failed.`);
        console.log(err)
      });
  }

  // handlePageClick = data => {
  //   this.showLoader()
  //   let selected = data.selected + 1;
  //   this.props.history.replace("?page=" + selected)
  //   this.setState({ currentPage: selected }, () => this.fetchData(this.state.currentPage))
  // };
  editshow = (uniqueSlug) => {
    this.toggleBannerEditModal()
    this.setState({ editSlug: uniqueSlug })
  }
  toggleBannerEditModal = () => {
    this.setState({ editshows: !this.state.editshows })
  }
  //  Search Impimentation
  onBlurHandel = () => {
    this.setState({ search: "" })
    getShowsDropDowns()
      .then(response => {
        this.setState({ shows: response.data })
      })
      .catch(err => console.log("onBlur err", err))
  }
  HandelSearch = (e) => {
    e.preventDefault()
    this.showLoader()

    let TargetValue = this.state.search
    console.log("taget", TargetValue)
    getShowsDropDowns()
      .then(response => {

        let shows = response.data
        // shows.find(data=>{
        //   console.log(data.title == val)
        // })
        if (TargetValue !== "") {

          let TargetValue2 = shows.filter(data => data.title.toLowerCase().includes(TargetValue.toLowerCase()))
          TargetValue2 != "" ? this.setState({ shows: TargetValue2 }) : this.setState({ shows: [{ title: "NO DATA" }] })
          this.hideLoader()
        } else {
          this.setState({ shows: response.data })
          this.hideLoader()
        }

      })
      .catch(err => console.log("serach err", err))
  }
  Searchchange = (e) => {
    this.setState({ search: e.target.value })
  };
  HandelFilter = (e) => {
    this.setState({ filter: e.target.value })
    // console.log("filter", e.target.value)
    this.showLoader()
    getShowsDropDowns(e.target.value)
      .then(response => {

        let shows = response.data
        this.setState({ shows: response.data })
        this.hideLoader()

      })
      .catch(err =>
        this.hideLoader()
      )
  }

  render() {
    const { shows } = this.state;
    let imgHash = Date.now()
    const loader = <ContentLoader backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>
    return (

      <div>
        <ToastContainer position="top-right" />
        <Container>
          <Modal size="xl" show={this.state.editshows} onHide={this.toggleBannerEditModal} >
            <Modal.Header closeButton>
              <Modal.Title>Edit show</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <NewShow data={this.state.editSlug} update={true} toggleBannerEditModal={this.toggleBannerEditModal} fetchData={this.fetchData} filter={this.state.filter} />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.toggleBannerEditModal}>Close</Button>
              {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
            </Modal.Footer>
          </Modal>
          <Row>
            <Col>
              <h2>Shows list ({this.state.shows.length}) </h2>
            </Col>
            <Col>
              {/* <input style={{ width: "80%", height: "40px", borderRadius: "12px", textDecoration: "none", outlineStyle: "none" }} type="text" placeholder="Search By Show Name" onChange={this.Searchchange} value={this.state.search} onBlur={this.onBlurHandel} /> &nbsp;
              <Button type="submit" variant="info">Publish</Button> */}
              <Form inline onSubmit={this.HandelSearch}>
                <Form.Control type="text" placeholder="Search By Show Name" onChange={this.Searchchange} value={this.state.search} className="mr-sm-4" />
                <Button className="mr-sm-3" type="submit" variant="info">Search</Button>
              </Form>
            </Col>
            <Col>
              <Form.Control as="select" name="filter" onChange={this.HandelFilter}>
                <option value="True">Active</option>
                <option value="False">In Active</option>
              </Form.Control>
            </Col>

          </Row>
        </Container> <br></br>

        <div>
          <Table responsive hover >
            <thead>
              <tr key="header">
                <th scope="col">Sr.no</th>
                {/* <th scope="col">Featured Image</th> */}
                <th scope="col">Show Name </th>
                {/* <th scope="col">Category</th> */}
                <th scope="col">Actions</th>

              </tr>
            </thead>
            <tbody>
              {
                !this.state.isLoading ?
                  shows.map((show, index) =>
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {/* <td><Image src={`${show.featuredImageUrl}?${imgHash}`} alt={show.title} width="80px" thumbnail></Image></td> */}
                      <td>{show.title}</td>

                      {/* <td>
                        {show.categories.map((category) => {
                          return (
                            <p key={index}>{category.name}</p>
                          );
                        })}
                      </td> */}

                      {show.title === "NO DATA" ? <td>{show.title}</td> :
                        <td>
                          <Link to={`/shows/edit-show-episode/${show.uniqueSlug}/${this.state.filter}`}>Edit Episodes</Link> &nbsp;
                          {/* <Link to={"/shows/edit-show?unique=" + show.uniqueSlug}>Edit Show</Link> &nbsp; */}
                          <Button variant="primary" onClick={() => this.editshow(show.uniqueSlug)}>Edit Show</Button>
                          <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete show ${show.title}?`)) this.onDelete(show.uniqueSlug, show.title) }}>Delete</Button>
                        </td>
                      }

                    </tr>

                  ) :
                  <tr>
                    <td>{loader}</td>
                    <td>{loader}</td>
                    <td>{loader}</td>
                    <td>{loader}</td>
                  </tr>
              }
            </tbody>
          </Table>

        </div>

        {/* {this.state.count === 0 ? <p>Please wait...</p> :
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakLabel={'...'}
            breakClassName={'break-me'}
            pageCount={this.state.paginationCount}
            onPageChange={this.handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={this.state.currentPage - 1}
            // initialPage={1}
          ></ReactPaginate>} */}
      </div>
    )
  }
}

export default AllShows

import React, { Component } from "react";
import {
  Table,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ContentLoader from "react-content-loader";
import {
  GetAllProducts,
  removeProducts,
} from "../../../../services/serviceSubscription";
import AddProducts from "./AddProducts";

class AllProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Products: [],
      isLoading: true,
      editproduct: false,
      editData: {},
    };
  }

  fetchData = () => {
    GetAllProducts()
      .then((res) => {
        this.setState({ Products: res.data, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
        // this.setState({ isLoading: false });
      });
  };
  componentDidMount() {
    this.fetchData();
  }

  async onDelete(uniqueSlug, title) {
    // this.toggleEdit(uniqueSlug)
    await removeProducts(uniqueSlug)
      .then((response) => {
        if (response.status === 204) {
          toast(`Prouct ${title} deleted successfully.`);
          this.fetchData();
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(`Show ${title} deleted failed.`);
        console.log(err);
      });
  }
  Oneditproduct = (data) => {
    this.toggleProductEditModal();
    this.setState({ editData: data });
  };
  toggleProductEditModal = () => {
    this.setState({ editproduct: !this.state.editproduct });
  };
  render() {
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );
    let { Products } = this.state;

    return (
      <div>
        <ToastContainer />
        <h1>All Products</h1>
        <Modal
          size="xl"
          show={this.state.editproduct}
          onHide={this.toggleProductEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddProducts
              data={this.state.editData}
              update={this.state.editproduct}
              toggleProductEditModal={this.toggleProductEditModal}
              fetchData={this.fetchData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleProductEditModal}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
          </Modal.Footer>
        </Modal>
        <div>
          <Table responsive hover>
            <thead>
              <tr key="header">
                <th scope="col">Sr.no</th>
                {/* <th scope="col">Featured Image</th> */}
                <th scope="col">Product Name </th>
                {/* <th scope="col">Category</th> */}
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {!this.state.isLoading ? (
                Products.map((product, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{product.name}</td>

                    {product.title === "NO DATA" ? (
                      <td>{product.title}</td>
                    ) : (
                      <td>
                        <Link
                          to={`/product/edit-product-plans/${product.uuid}`}
                        >
                          Edit Plan
                        </Link>{" "}
                        &nbsp;
                        {/* <Link to={"/products/edit-product?unique=" + product.uniqueSlug}>Edit product</Link> &nbsp; */}
                        <Button
                          variant="primary"
                          onClick={() => this.Oneditproduct(product)}
                        >
                          Edit product
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you wish to delete product ${product.name}?`
                              )
                            )
                              this.onDelete(product.uuid, product.name);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                <tr>
                  <td>{loader}</td>
                  <td>{loader}</td>
                  <td>{loader}</td>
                  <td>{loader}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

export default AllProducts;

import React, { Component } from 'react'
import { Form, Col, Button, Row, Table, Container, Modal } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route, Link, NavLink } from 'react-router-dom';
import logo from '../../../images/aawaz-logo.png';
import classes from './sidebar.module.css'
export default class Sidebar extends Component {
    render() {
        return (

            <Col className="centerAlign">
                <img src={logo} className="logo" alt="Aawaz logo" />
                <h5 className="login-title">Set up your profile</h5>
                <p className="subtitle live-nav">
                    <NavLink exact to={'/rj-profile'} activeClassName={classes.active} className="nav-link"> Create Profile</NavLink>
                    <NavLink exact to={'/rj-list'} activeClassName={classes.active} className="nav-link"> Rj List </NavLink>
                    <NavLink exact to={'/guest-list'} activeClassName={classes.active} className="nav-link"> Guest List </NavLink>
                    <NavLink exact to={'/live-show-list'} activeClassName={classes.active} className="nav-link">Live Shows List</NavLink>
                    <NavLink exact to={'/live-show'} activeClassName={classes.active} className="nav-link"> Live Show Upload </NavLink>
                </p>


                {/* <Router>
                        <Switch>
                            <Route path='/rj-profile' component={RjProfile} />
                            <Route path='/live-show' component={LiveShowDash} />

                        </Switch>
                    </Router> */}
            </Col>

        )
    }
}

import http from '../http-common';
import auth from './authentication';
const baseURL = "https://preprod.aawaz.com/api";

const removeEpisode = (id) => {
    return http.delete(`${baseURL}/episodes/${id}/`, { auth: auth });
};

const sequenceEpisode = (id, data) => {
    let sequencedata = {
        "episode_slugs": data
    }
    return http.post(`${baseURL}/shows/${id}/update_episode_sequence/`, sequencedata, { auth: auth });
};
const liveEpisodes = () => {
    return http.get(`${baseURL}/episodes/?is_live=True`, { auth: auth });
}


export {
    removeEpisode, sequenceEpisode, liveEpisodes
}
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  Alert,
  Container,
  Row,
  Col,
  Image,
  Modal,
  Form,
} from "react-bootstrap";
import ContentLoader from "react-content-loader";
import queryString from "query-string";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getAlldashboardV3,
  removedashboardV3,
} from "../../../services/serviceSections";
import AddDahboardV3 from "./AddDahboardV3";
export default class AllDashboardV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DasboardData: [],
      EditId: "",
      edit: false,
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    getAlldashboardV3()
      .then((res) => {
        // console.log("yyy", res.data);
        this.setState({ DasboardData: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  onDelete = (id, title) => {
    removedashboardV3(id)
      .then((res) => {
        toast.success(`dashboard ${title} delete sucessfully !`);
        this.fetchData();
      })
      .catch((err) => {
        toast.error(`dashboard ${title} delete failed !`);
      });
  };
  editsection = (id) => {
    this.setState({ EditId: id, edit: true });
  };
  toggleDashboardEditModal = () => {
    this.setState({ edit: !this.state.edit });
  };
  render() {
    const loader = (
      <ContentLoader backgroundColor="#c2c2c2">
        <rect x="0" y="56" rx="3" ry="3" width="150" height="4" />
        <rect x="0" y="72" rx="3" ry="3" width="100" height="4" />
      </ContentLoader>
    );

    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center"
        />
        <Modal
          size="xl"
          show={this.state.edit}
          onHide={this.toggleDashboardEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit show</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddDahboardV3
              data={this.state.EditId}
              update={this.state.edit}
              toggleDashboardEditModal={this.toggleDashboardEditModal}
              fetchData={this.fetchData}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.toggleBannerEditModal}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
          </Modal.Footer>
        </Modal>
        <Table responsive hover>
          <thead>
            <tr key="header">
              <th scope="col">Sr.no</th>
              {/* <th scope="col">Featured Image</th> */}
              <th scope="col">section Name </th>
              {/* <th scope="col">Category</th> */}
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {!this.state.isLoading ? (
              this.state.DasboardData.map((section, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  {/* <td><Image src={`${section.featuredImageUrl}?${imgHash}`} alt={section.title} width="80px" thumbnail></Image></td> */}
                  <td>{section.title}</td>

                  {/* <td>
                        {section.categories.map((category) => {
                          return (
                            <p key={index}>{category.name}</p>
                          );
                        })}
                      </td> */}

                  {section.title === "NO DATA" ? (
                    <td>{section.title}</td>
                  ) : (
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => this.editsection(section.uniqueSlug)}
                      >
                        Edit section
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => {
                          if (
                            window.confirm(
                              `Are you sure you wish to delete dashboard ${section.title}?`
                            )
                          )
                            this.onDelete(section.uniqueSlug, section.title);
                        }}
                      >
                        Delete
                      </Button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
                <td>{loader}</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    );
  }
}

import React, { Component } from 'react'
import { Form, Button, Container, Row, Col, Figure } from 'react-bootstrap';
import logo from '../../../images/aawaz-logo.png';
import profilePic from '../../../images/profilePic.png';
import '../rjProfile/profile-page.css';
import jQuery from 'jquery';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
// import timeago from 'epoch-timeago';
import DatePicker from "react-datepicker";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import rjProfile from '../rjProfile/rjProfilePage';
import rjList from '../RjList/RjList'
import AllLiveShow from '../AllLiveShows/AllLiveShows'
import { AddLiveShow, UpdateLiveShow, AllListRj, AllGuestList } from '../../../services/serviceLive'
import Sidebar from '../Sidebar/Sidebar'
import Select from 'react-select';



class liveShowPage extends Component {

    state = {
        title: "",
        subtitle: "",
        channelName: "",
        currentSlug: "",
        description: "",
        featuredImageUrl: "",
        bannerImageUrl: "",
        fullImageUrl: "",
        language: "",
        startTimeStr: "",
        beginTime: "",
        finishTime: "",
        endTimeStr: "",
        uniqueSlug: "",
        shortUrl: "",
        is_recurring: false,
        // rjName: "",
        categoryName: "",
        guest_slugs: '',
        rj_slugs: '',
        org_slug: '',
        guest: [],
        rj: []

    }



    //  Date picker Handler

    handleCallback = (start, end, label) => {
        console.log(start)
        console.log(end)
        var myEpochStart = start._d.getTime() / 1000.0;
        var myEpochEnd = end._d.getTime() / 1000.0;
        this.setState({ beginTime: myEpochStart, finishTime: myEpochEnd })
        console.log("start end", myEpochEnd);
        console.log(" end", myEpochStart);

        let unixTimestamp = myEpochStart;
        let dates = new Date(unixTimestamp * 1000);
        let unixTimestampend = myEpochEnd;
        let datesend = new Date(unixTimestampend * 1000);
        let StateTimeString = dates.toDateString() + " " + dates.toLocaleTimeString()
        let endTimeString = datesend.toDateString() + " " + datesend.toLocaleTimeString()
        this.setState({ startTimeStr: StateTimeString, endTimeStr: endTimeString })
        console.log("start", StateTimeString)
    }


    //   filed handleler
    handleChange = (e) => {
        if (e.target.name === "guest") {
            this.setState({ [e.target.name]: [e.target.value] })
        } else {
            this.setState({ [e.target.name]: [e.target.value] })
        }

        // console.log("aua ", this.state)
    }

    selectHandler = (event, value) => {

        value.name === "guest_slugs" ?
            this.setState({ guest_slugs: event }) :
            this.setState({ rj_slugs: event })
    }

    // Submit handleler
    submitHandler = (e) => {
        e.preventDefault()
        console.log("Live show state", this.state)
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'rj_slugs':
                    if (this.state.rj_slugs != "") {
                        let Rjdata = JSON.stringify(this.state.rj_slugs)

                        formData.append(`${key}`, Rjdata)
                    }
                    break;
                case 'guest_slugs':
                    if (this.state.guest_slugs != "") {
                        let Guestdata = JSON.stringify(this.state.guest_slugs)

                        formData.append(`${key}`, Guestdata)
                    }
                    break;
                case 'guest':
                    break;
                case 'rj':
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        AddLiveShow(formData)
            .then(response => {
                if (response.status === 201) {
                    toast.success(` Live Show created successfully.`)
                    this.setState({
                        title: "",
                        subtitle: "",
                        channelName: "",
                        currentSlug: "",
                        description: "",
                        featuredImageUrl: "",
                        bannerImageUrl: "",
                        fullImageUrl: "",
                        language: "",
                        startTimeStr: "",
                        beginTime: "",
                        endTimeStr: "",
                        finishTime: null,
                        uniqueSlug: "",
                        shortUrl: "",
                        rj_slugs: "",
                        guest_slugs: "",
                        categoryName: "",
                        featuredImageView: "",
                        fullImageView: "",
                        bannerImageView: "",
                        org_slug: '',
                    })
                }
                if (response.status === 400) {
                    toast.error('Something went wrong, please try again')
                    this.setState({ isLoading: false })
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    let err = "";
                    for (let [key, value] of Object.entries(error.response.data)) {
                        err = `${key} :${value[0]}. `
                    }
                    toast.error(err)
                }
                console.log(error)
            })
    }
    updateLiveShowData = () => {
        let formData = new FormData();
        for (let [key, value] of Object.entries(this.state)) {
            switch (key) {
                case 'guest':
                    break;
                case 'rj':
                    break;
                case 'rj_slugs':
                    if (this.state.rj_slugs.length !== 0) {
                        let Rjdata = JSON.stringify(this.state.rj_slugs)

                        formData.append(`${key}`, Rjdata)
                    }
                    break;
                case 'guest_slugs':
                    if (this.state.guest_slugs.length !== 0) {
                        let Guestdata = JSON.stringify(this.state.guest_slugs)

                        formData.append(`${key}`, Guestdata)
                    }
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        UpdateLiveShow(this.state.uniqueSlug, formData)
            .then(response => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(` Live Show Updated successfully.`)
                    this.setState({
                        title: "",
                        subtitle: "",
                        channelName: "",
                        currentSlug: "",
                        description: "",
                        featuredImageUrl: "",
                        bannerImageUrl: "",
                        fullImageUrl: "",
                        language: "",
                        startTimeStr: "",
                        beginTime: "",
                        finishTime: "",
                        endTimeStr: "",
                        uniqueSlug: "",
                        shortUrl: "",
                        rj_slugs: "",
                        guest_slugs: "",
                        categoryName: "",
                        featuredImageView: "",
                        fullImageView: "",
                        bannerImageView: "",
                        org_slug: '',
                    })
                    this.props.toggleLiveShowEditModal()
                }
                if (response.status === 400) {
                    toast.error('Something went wrong, please try again')
                    this.setState({ isLoading: false })
                }
            })
            .catch(error => {
                if (error.response.status === 400) {
                    let err = "";
                    for (let [key, value] of Object.entries(error.response.data)) {
                        err = `${key} ${value[0]}. `
                    }
                    toast.error(err)
                }
                console.log(error)
            })
    }
    featuredImageChange = (event) => {
        this.setState({ featuredImageView: undefined, featured_image: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ featured_image: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ featuredImageView: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    bannerImageChange = (event) => {
        this.setState({ bannerImageView: undefined, banner_image: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ banner_image: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ bannerImageView: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    fullImageChange = (event) => {
        this.setState({ fullImageView: undefined, full_image: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            this.setState({ full_image: event.target.files[0] })
            reader.onload = (e) => {
                this.setState({ fullImageView: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    componentDidMount = () => {
        if (this.props.editedData) {
            console.log("live show", this.props.editedData)
            // var myDateStart = new Date(this.props.editedData.beginTime * 1000);
            // var start = myDateStart.toLocaleString()
            // var myDateEnd = new Date(this.props.editedData.finishTime * 1000);
            // var End = myDateEnd.toLocaleString()
            // console.log("data", start, End)

            let rjslug = []
            this.props.editedData.rj.forEach(data => {
                rjslug.push({ value: data.uniqueSlug, label: data.name })
            })
            let guestSlug = []
            this.props.editedData.guest.forEach(data => {
                guestSlug.push({ value: data.uniqueSlug, label: data.name })
            })
            this.setState({
                title: this.props.editedData.title,
                subtitle: this.props.editedData.subtitle,
                channelName: this.props.editedData.channelName,
                description: this.props.editedData.description,
                featuredImageUrl: this.props.editedData.featuredImageUrl,
                featuredImageView: this.props.editedData.featuredImageUrl,
                bannerImageUrl: this.props.editedData.bannerImageUrl,
                bannerImageView: this.props.editedData.bannerImageUrl,
                fullImageUrl: this.props.editedData.fullImageUrl,
                fullImageView: this.props.editedData.fullImageUrl,
                language: this.props.editedData.language,
                startTimeStr: this.props.editedData.startTime,
                beginTime: this.props.editedData.beginTime,
                finishTime: this.props.editedData.finishTime,
                endTimeStr: this.props.editedData.endTime,
                uniqueSlug: this.props.editedData.uniqueSlug,
                shortUrl: this.props.editedData.shortUrl,
                org_slug: this.props.editedData.org_slug,
                rj_slugs: rjslug,
                guest_slugs: guestSlug,
                categoryName: this.props.editedData.categoryName,
                is_recurring: this.props.editedData.isRecurring
            })

            // let epochTime = TimeInterval(this.props.editedData.beginTime) / 1000
            // let date = Date(epochTime)   // "Apr 16, 2015, 2:40 AM"

            // console.log("date", date)

        }
        AllListRj()
            .then(response => {
                let newOptions = []
                let rjData = response.data

                rjData.forEach(data => {
                    newOptions.push({ value: data.uniqueSlug, label: data.name })
                })
                this.setState({ rj: newOptions })
            })
            .catch(error => {
                toast.error("rj fetch failed")
                console.log(error)
            });
        AllGuestList()
            .then(response => {
                let newOptions = []
                let guestData = response.data

                guestData.forEach(data => {
                    newOptions.push({ value: data.uniqueSlug, label: data.name })
                })
                this.setState({ guest: newOptions })
            })
            .catch(error => {
                toast.error("guest fetch failed")
                console.log(error)
            });
    }
    handelCheckbox = () => {
        this.setState({ is_recurring: !this.state.is_recurring })
    }
    handleDateChange = date => {
        this.setState({
            begindateTime: date
        });
        var myEpochEnd = Math.floor(date.getTime() / 1000.0)
        this.setState({ beginTime: myEpochEnd })
        console.log("beginTime", myEpochEnd);
        let unixTimestampend = myEpochEnd;
        let datesend = new Date(unixTimestampend * 1000);
        let endTimeString = datesend.toDateString() + " " + datesend.toLocaleTimeString()
        this.setState({ startTimeStr: endTimeString })
    };
    handleDateChange2 = date => {
        this.setState({
            finishdateTime: date
        });
        var myEpochEnd = Math.floor(date / 1000)
        this.setState({ finishTime: myEpochEnd })
        console.log("finish", myEpochEnd);
        let unixTimestampend = myEpochEnd;
        let datesend = new Date(unixTimestampend * 1000);
        let endTimeString = datesend.toDateString() + " " + datesend.toLocaleTimeString()
        this.setState({ endTimeStr: endTimeString })
    };

    render() {
        console.log("guest", this.state)
        return (
            <>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    position="top-center" />

                <Container>
                    <Row>
                        <Sidebar />
                        <Col className="centerAlign">
                            <Form className="profile-form" onSubmit={this.submitHandler}>
                                {/* <img src={profilePic} className="profile-pic" alt="Profile picture" /> */}

                                {/* "title": "liveShowforTesting2" */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Show Title" name="title" value={this.state.title} onChange={this.handleChange} />
                                </Form.Group>
                                {/* "subtitle": "liveShowforTesting2", */}

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Subtitle" name="subtitle" value={this.state.subtitle} onChange={this.handleChange} />
                                </Form.Group>
                                {/* "channelName": "Radio Entertainment", */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Channel Name" name="channelName" value={this.state.channelName} onChange={this.handleChange} />
                                </Form.Group>
                                {/* Feature Image */}
                                {this.state.featuredImageView ?
                                    <Figure>
                                        <Figure.Image src={this.state.featuredImageView || this.state.featuredImageUrl} thumbnail className="featureImage" />
                                    </Figure> : null
                                }
                                <Form.File type="file" name="featured_image" label="Featured Image Upload" accept="image/*" custom onChange={this.featuredImageChange} />
                                {/* Feature Image Url */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="featured Image Url" name="featuredImageUrl" value={this.state.featuredImageUrl} onChange={this.handleChange} />
                                </Form.Group>
                                {/* Banner Image */}
                                {this.state.bannerImageView ?
                                    <Figure>
                                        <Figure.Image src={this.state.bannerImageView || this.state.bannerImageUrl} thumbnail className="featureImage" />
                                    </Figure> : null
                                }
                                <Form.File type="file" name="banner_image" label="Banner Image Upload" accept="image/*" custom onChange={this.bannerImageChange} />
                                {/* Banner Image Url*/}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="banner Image Url" name="bannerImageUrl" value={this.state.bannerImageUrl} onChange={this.handleChange} />
                                </Form.Group>
                                {/* full Image */}
                                {this.state.fullImageView ?
                                    <Figure>
                                        <Figure.Image src={this.state.fullImageView || this.state.fullImageUrl} thumbnail className="featureImage" />
                                    </Figure> : null
                                }
                                <Form.File type="file" name="full_image" label="Full Image Upload" accept="image/*" custom onChange={this.fullImageChange} />
                                {/* full Image Url */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control as='textarea' className="login-input" type="text" placeholder="Full Image Url" name="fullImageUrl" value={this.state.fullImageUrl} onChange={this.handleChange} />
                                </Form.Group>
                                {/* "description": "liveShowforTesting2", */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control as='textarea' className="login-input" type="text" placeholder="Description" name="description" value={this.state.description} onChange={this.handleChange} />
                                </Form.Group>

                                {/* categoryName */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Category Name" name="categoryName" value={this.state.categoryName} onChange={this.handleChange} />
                                </Form.Group>
                                {/* guest */}
                                <Form.Group controlId="formBasicEmail">
                                    {/* {this.state.guest.map((item) => (
                                        item == [] ? <Form.Control className="login-input" type="text" placeholder="Guest" name="guest" value={item.name} onChange={this.handleChange} />
                                            : <Form.Control className="login-input" type="text" placeholder="Guest" name="guest" value={this.state.guest} onChange={this.handleChange} />
                                    ))
                                    } */}

                                    {/* <Form.Control className="login-input" type="text" placeholder="Guest" name="guest" value={this.state.guest} onChange={this.handleChange} /> */}
                                    <Col>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Select className="login-input" isMulti defaultValue value={this.state.guest_slugs} styles={{ control: styles => ({ ...styles, borderRadius: '35px', border: 'none', backgroundColor: '#fff8fb' }) }} name="guest_slugs"
                                                placeholder="Select Guest" onChange={this.selectHandler} options={this.state.guest} />
                                            {/* <Form.Control className="login-input" as="select" name="guest_slugs" value={this.state.guest_slugs} onChange={this.handleChange}>
                                                <option defaultValue value="">Select Guest</option>
                                                {this.state.guest.map((data, index) =>
                                                    <option key={index} value={data.uniqueSlug}>{data.name}</option>

                                                )} */}

                                            {/* </Form.Control> */}
                                        </Form.Group>
                                    </Col>
                                </Form.Group>
                                <Row>
                                    {/* rjName */}
                                    <Col>
                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                            <Select className="login-input" isMulti defaultValue value={this.state.rj_slugs} styles={{ control: styles => ({ ...styles, borderRadius: '35px', border: 'none', backgroundColor: '#fff8fb', width: '300' }) }} name="rj_slugs"
                                                placeholder="Select Rj" onChange={this.selectHandler} options={this.state.rj} />

                                            {/* <Form.Control className="login-input" as="select" name="rj_slugs " value={this.state.rj_slugs} onChange={this.handleChange}>
                                                <option defaultValue value="">Select Rj</option>
                                                {this.state.rjLists.map((data, index) =>
                                                    <option key={index} value={data.uniqueSlug}>{data.name}</option>

                                                )}

                                            </Form.Control> */}
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Control className="login-input" as="select" name="language" value={this.state.language} onChange={this.handleChange}>
                                            <option defaultValue value="">Select a Language</option>
                                            <option value="en">English</option>
                                            <option value="hi">Hindi</option>
                                            <option value="mr">Marathi</option>
                                            <option value="ur">Urdu</option>
                                        </Form.Control>
                                    </Col>
                                </Row>
                                {/* Organization */}
                                <Form.Group controlId="formBasicPassword">
                                    <Form.Control className="login-input" type='text' placeholder="Orgnization" name="org_slug" value={this.state.org_slug} onChange={this.handleChange} />
                                </Form.Group>
                                {/* Short Url  */}
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Short Url" name="shortUrl" value={this.state.shortUrl} onChange={this.handleChange} />
                                </Form.Group>
                                {/* <Form.Group controlId="formBasicEmail"> */}
                                <div style={{ display: "flex" }}>
                                    <p>Is recurring</p>
                                    <Form.Control style={{ width: "14px", height: "27px", marginLeft: "12px" }} type="checkbox" name="is_recurring" checked={this.state.is_recurring} onClick={this.handelCheckbox} />
                                </div>
                                <Form.Group controlId="formBasicPassword">
                                    <Col>
                                        <DatePicker
                                            name="beginTime"
                                            selected={this.state.begindateTime}
                                            value={this.state.startTimeStr}
                                            onChange={this.handleDateChange}
                                            showTimeSelect
                                            timeFormat="HH:mm"
                                            timeIntervals={10}
                                            timeCaption="time"
                                            dateFormat="MMMM d, yyyy h:mm aa"
                                            placeholderText="Begin time"

                                        />
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="formBasicPassword">
                                    <Col> <DatePicker
                                        name="finishTime"
                                        // selected={this.state.finishTime}
                                        selected={this.state.finishdateTime}
                                        value={this.state.endTimeStr}
                                        onChange={this.handleDateChange2}
                                        showTimeSelect
                                        timeFormat="HH:mm"
                                        timeIntervals={10}
                                        timeCaption="time"
                                        dateFormat="MMMM d, yyyy h:mm aa"
                                        placeholderText="Finish time"


                                    /></Col>

                                    {/* <DateRangePicker
                                        initialSettings={{
                                            className: 'login-input',
                                            timePicker: true,
                                            startDate: moment().startOf('hour').toDate(),
                                            endDate: moment().startOf('hour').add(32, 'hour').toDate(),
                                            locale: {
                                                format: 'M/DD/y hh:mm A',
                                            },

                                        }}
                                        onCallback={this.handleCallback}
                                    >
                                        <input type="text" placeholder="Begin Date Time , End Date time" value={`${this.state.beginTime} ,${this.state.finishTime}`} className="form-control col-20" />
                                    </DateRangePicker> */}
                                </Form.Group>
                                {/* </Form.Group> */}
                                {/* Start time  */}
                                {/* <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="Start Time" name="startTime" value={this.state.startTime} onChange={this.handleChange} />
                                </Form.Group> */}
                                {/* End Time */}
                                {/* <Form.Group controlId="formBasicEmail">
                                    <Form.Control className="login-input" type="text" placeholder="End Time" name="endTime" value={this.state.endTime} onChange={this.handleChange} />
                                </Form.Group> */}
                                {this.props.update === true ? <Button className="login-button" variant="outline-light" onClick={this.updateLiveShowData}>
                                    UPDATE AND GET STARTED
                                </Button> : <Button className="login-button" variant="outline-light" type="submit">
                                    FINISH AND GET STARTED
                                </Button>}
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}

export default liveShowPage
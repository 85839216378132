import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form, Col, Button, Figure } from "react-bootstrap";
import {
  createProducts,
  updateProducts,
  removeProducts,
} from "../../../../services/serviceSubscription";

class AddProducts extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", description: "", isLoading: false };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    if (this.props.hasOwnProperty("data")) {
      this.setState({
        name: this.props.data.name,
        description: this.props.data.description,
      });
    }
  }
  // SUBMIT HANDELER
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "name":
          formData.append(`${key}`, value);
          break;
        case "description":
          formData.append(`${key}`, value);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }

    // CREATE PRODUCTS

    createProducts(formData)
      .then((res) => {
        if (res.status === 201 || 200) {
          toast.success("product add sucessfully !");
          this.setState({ name: "", description: "", isLoading: false });
        }
      })
      .catch((err) => {
        toast.error("product add Failed !");
        console.log(err);
      });
  };

  // UPDATE HANDELER
  updateHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "isLoading":
        case "name":
          formData.append(`${key}`, value);
          break;
        case "description":
          formData.append(`${key}`, value);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    updateProducts(this.props.data.uuid, formData)
      .then((res) => {
        this.props.toggleProductEditModal();
        this.props.fetchData();
        toast.success("product update sucessfully !");
        this.setState({
          name: "",
          description: "",
          uuid: "",
          isLoading: false,
        });
      })
      .catch((err) => {
        toast.error("product update Failed !");
        console.log(err);
      });
  };

  render() {
    let { name, description } = this.state;
    return (
      <div>
        <ToastContainer />
        {!this.props.update && <h1>Add Product</h1>}
        <Form onSubmit={this.submitHandler}>
          <Form.Group>
            <Col>
              <Form.Label>
                Product Name: <span className="asterisk"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Product Name here"
                name="name"
                value={name}
                onChange={this.changeHandler}
                maxLength="255"
                required
              />
            </Col>
            <Col>
              <Form.Label>
                Product description: <span className="asterisk"> *</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                placeholder="Enter Product description here"
                name="description"
                value={description}
                onChange={this.changeHandler}
              />
            </Col>
          </Form.Group>
          {this.props.update ? (
            <Button onClick={this.updateHandler}>Update</Button>
          ) : (
            <Button type="submit" variant="info">
              Publish
            </Button>
          )}
        </Form>
      </div>
    );
  }
}

export default AddProducts;

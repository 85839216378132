import http from '../http-common';
import auth from './authentication';
const baseURL = "https://preprod.aawaz.com";
// let auth ={username: "aawazadmin", password:"aawazadmin"}


const createRss = (data) => {
    return http.post(`${baseURL}/rss/rss-updates/`, data, { auth: auth });
}
const RssShow = () => {
    return http.get(`${baseURL}/rss/show-details/`, { auth: auth });
}
const RssList = (page = 1) => {
    if (page == '1') {

        return http.get(`${baseURL}/rss/rss-updates/?limit=10`, { auth: auth })
    } else {
        return http.get(`${baseURL}/rss/rss-updates/?limit=10&offset=${page - 1}0`, { auth: auth });
    }

}
const UpdateRss = (id, data) => {
    return http.patch(`${baseURL}/rss/rss-updates/${id}/`, data, { auth: auth });
}
const deleteRss = id => {
    return http.delete(`${baseURL}/rss/rss-updates/${id}/`, { auth: auth });
}
const LiveRss = () => {
    return http.get(`${baseURL}/api/shows/update_rss_feed/`, { auth: auth });
}

export {
    createRss,
    RssShow,
    UpdateRss,
    deleteRss,
    RssList,
    LiveRss
}

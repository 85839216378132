import React, { Component } from 'react';
import { Table, Image, Button, Figure, Row, Col, Container, Form } from 'react-bootstrap';
import ContentLoader from "react-content-loader"
import update from 'immutability-helper';
import * as Icons from 'react-bootstrap-icons'

import { baseURL, removeChannel, getAllChannel, getAllChannelPagination } from '../../../services/serviceChannels';
import axios from 'axios';
import auth from '../../../services/authentication';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { ChromePicker } from 'react-color'


class ChannelSheet extends Component {

    state = {
        next: "not null",
        totalChannel: 0,
        paginationCount: 0,
        currentPage: 1,
        Channel: [],
        editedChannel: {},
        readFeaturedImg: undefined,
        readBannerImg: undefined,
        featuredImg: undefined,
        bannerImg: undefined,
        isLoading: false,
        addNewShow: false,
        uniqueSlug: "",
        title: ""
    }

    hideLoader = () => {
        this.setState({ isLoading: false });
    }

    showLoader = () => {
        this.setState({ isLoading: true });
    }

    fetchData = async (page) => {
        this.showLoader();
        await getAllChannelPagination(page).then(response => {
            this.setState({ Channel: response.data.results, next: response.data.next, paginationCount: Math.ceil(response.data.count / 10), totalChannel: response.data.count })
            this.hideLoader();
        })
            .catch(error => {
                toast.error("Error occured while fetching data")
                console.log(error)
                this.hideLoader();
            });
    }

    beforeunload(e) {
        if (this.state.editedChannel.dirty || this.state.addNewShow) {
            e.preventDefault();
            e.returnValue = true;
        }
    }

    componentDidMount = () => {
        window.addEventListener('beforeunload', this.beforeunload.bind(this));
        this.fetchData(this.state.currentPage)
    }
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeunload.bind(this));
    }

    toggleEdit = (slug, show) => {
        if (this.state.editedChannel.dirty && window.confirm(`There are unsaved values in current channel "${this.state.editedChannel.title}", Do you want to proceed without saving? updated values will be lost!`)) {
            if (this.state.addNewShow) {
                let prevChannel = this.state.Channel
                prevChannel.shift()
                this.toggleAddNewShow();
                this.setState({ Channel: prevChannel })
            }
            this.setState({
                uniqueSlug: slug,
                bannerImg: undefined,
                featuredImg: undefined,
                readBannerImg: undefined,
                readFeaturedImg: undefined,
                editedChannel: {
                    title: show.title,
                    description: show.description,
                    short_description: show.shortDescription,
                    featured_image: show.featuredImageUrl,
                    banner_image: show.bannerImageUrl,
                    published_on: show.publishedOn,
                    short_url: show.shortUrl,
                    weight: show.weight,
                    color: show.color || "#000",
                    sequence: show.sequence,
                    channel_slug: show.uniqueSlug,
                    dirty: false
                }
            })
        }
        if (!this.state.uniqueSlug || !this.state.editedChannel.dirty) {
            this.setState({
                uniqueSlug: slug,
                bannerImg: undefined,
                featuredImg: undefined,
                readBannerImg: undefined,
                readFeaturedImg: undefined,
                editedChannel: {
                    title: show.title,
                    description: show.description,
                    short_description: show.shortDescription,
                    featured_image: show.featuredImageUrl,
                    banner_image: show.bannerImageUrl,
                    published_on: show.publishedOn,
                    short_url: show.shortUrl,
                    weight: show.weight,
                    color: show.color || "#000",
                    sequence: show.sequence,
                    channel_slug: show.uniqueSlug,
                    dirty: false
                }
            })
        }
    }

    toggleAddNewShow = () => {
        this.setState({ addNewShow: !this.state.addNewShow })
    }

    cancelAddNewShow = () => {
        let prevChannel = this.state.Channel
        prevChannel.shift()
        this.toggleAddNewShow();
        this.setState({ Channel: prevChannel })
    }

    editShowHandler = (event) => {
        let preveditedChannel = this.state.editedChannel
        preveditedChannel.dirty = true;
        let neweditedChannel = update(preveditedChannel, event.target.name === "weight" || event.target.name === "sequence" ? { [event.target.name]: { $set: parseInt(event.target.value) } } : { [event.target.name]: { $set: event.target.value } })
        this.setState({ editedChannel: neweditedChannel })
    }

    updateShowHandler = () => {
        this.showLoader()
        let data = this.state.editedChannel;
        let formData = new FormData();
        if (this.state.featuredImg) formData.append('featured_image', this.state.featuredImg)
        if (this.state.bannerImg) formData.append('banner_image', this.state.bannerImg)
        for (let [key, value] of Object.entries(data)) {
            switch (key) {
                case 'dirty':
                case 'featured_image':
                case 'banner_image':
                case 'channel_slug':
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }
        console.log('Formdata: ', data);

        axios({
            method: "PATCH",
            url: `${baseURL}/channels/${this.state.editedChannel.channel_slug}/`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            auth: auth,
            data: formData
        })
            .then(() => {
                toast.success(`Channel "${this.state.editedChannel.title}" updated!`)
                this.setState({ editedChannel: {}, uniqueSlug: "" }, () => this.fetchData(this.state.currentPage))
            })
            .catch(error => {
                if (error.response.status === 400) {
                    let err = "";
                    for (let [key, value] of Object.entries(error.response.data)) {
                        err = `${key} ${value[0]}. `
                    }
                    toast.error(err)
                    this.hideLoader()
                }
                this.hideLoader()
            })
    }

    deleteShowHandler = (slug, show) => {
        this.showLoader()
        removeChannel(slug)
            .then(() => {
                toast.success(`Channel "${show}" deleted successfully!`)
                if (this.state.next === null && this.state.Channel.length < 2 && this.state.currentPage !== 1) {
                    this.setState({ currentPage: this.state.currentPage - 1 }, () => this.fetchData(this.state.currentPage))
                }
                else {
                    this.fetchData(this.state.currentPage)
                }
            })
            .catch(err => {
                console.log("Channel delete error: ", err)
                toast.error(`Channel "${show}" delete unsuccessful!`)
            })
    }

    updateData = async () => {
        this.fetchData(this.state.currentPage)
        this.addShowHandler()
    }

    addShowHandler = () => {
        let prevChannel = this.state.Channel
        prevChannel.unshift({
            title: "",
            description: "",
            short_description: "",
            featured_image: "",
            banner_image: "",
            short_url: "blank",
            banner_image_url: "",
            featured_image_url: "",
            bannerImageUrl: "",
            featuredImageUrl: "",
            weight: 1,
            sequence: 1,
            dirty: false
        })
        this.toggleAddNewShow();
        this.setState({ Channel: prevChannel, uniqueSlug: "" })
    }

    addNewShowHandler = () => {
        this.showLoader()
        let data = this.state.editedChannel;
        let formData = new FormData();
        if (this.state.featuredImg) formData.append('featured_image', this.state.featuredImg)
        if (this.state.bannerImg) formData.append('banner_image', this.state.bannerImg)
        for (let [key, value] of Object.entries(data)) {
            switch (key) {
                case 'featured_image':
                case 'banner_image':
                case 'dirty':
                case 'channel_slug':
                    break;
                case 'published_on':
                    formData.append(`${key}`, `${moment(new Date()).format()}`)
                    break;
                default:
                    formData.append(`${key}`, value)
            }
        }

        for (var [key, value] of formData.entries()) {
            if (value === '' || value === undefined || value === {}) {
                this.setState({ isLoading: false })
                return toast.error(`${key} is required!`)
            }
        }
        axios({
            method: "POST",
            url: `${baseURL}/channels/`,
            headers: {
                "Content-Type": "multipart/form-data"
            },
            auth: auth,
            data: formData
        })
            .then(() => {
                toast.success(`New Channel "${this.state.editedChannel.title}" added!`)
                this.setState({ editedChannel: {}, addNewShow: false }, () => this.fetchData(this.state.currentPage))
            })
            .catch(error => {
                console.log(error.response)
                if (error.response.status === 400) {
                    toast.error(JSON.stringify(error.response.data))
                    this.setState({ isLoading: false })
                }
                console.log(error)
            })
    }

    onFeaturedImageChange = (event) => {
        this.setState({ readFeaturedImg: undefined, featuredImg: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            let editedChannel = this.state.editedChannel
            editedChannel.dirty = true
            this.setState({ featuredImg: event.target.files[0], editedChannel: editedChannel })
            reader.onload = (e) => {
                this.setState({ readFeaturedImg: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }

    onBannerImageChange = (event) => {
        this.setState({ readBannerImg: undefined, bannerImg: undefined })
        if (event.target.files[0]) {
            let reader = new FileReader();
            let editedChannel = this.state.editedChannel
            editedChannel.dirty = true
            this.setState({ bannerImg: event.target.files[0], editedChannel: editedChannel })
            reader.onload = (e) => {
                this.setState({ readBannerImg: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    }
    cancelEditing = () => {
        this.setState({ editedChannel: {}, uniqueSlug: "" })
    }


    colorPickerHandler = (color) => {
        console.log(color.hex)
        let newSection = this.state.editedChannel
        newSection.color = color.hex
        newSection.dirty = true
        this.setState({ editedChannel: newSection }, () => console.log(this.state.editedChannel))
    }
    handlePageClick = data => {
        this.showLoader()
        let selected = data.selected + 1;
        this.props.history.replace("?page=" + selected)
        this.setState({ currentPage: selected }, () => this.fetchData(this.state.currentPage))
    };

    render() {
        const { Channel, editedChannel } = this.state;
        console.log("channel sheet state", this.state)
        const numberStyle = {
            width: "40px"
        }
        const imgHash = Date.now();
        const loader = <ContentLoader backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>

        return (
            <Container>
                <div>
                    <ToastContainer position="top-right" />
                    <Row>
                        <Col>
                            <h1>Channel List ({this.state.totalChannel})</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>(Double click on a row to edit)</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {!this.state.addNewShow ? <Button variant="outline-success" title="Add New Channel" onClick={this.addShowHandler}><Icons.Plus /></Button> : null}  &nbsp;
                            <Button variant="outline-info" title="Refresh List" onClick={this.updateData}><Icons.ArrowClockwise /></Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Title<span className="asterisk">*</span></th>
                                                <th>Featured&nbsp;Image</th>
                                                <th>Featured Image Url</th>
                                                <th>Image Url</th>
                                                <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Banner&nbsp;Image&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                                                <th>Banner Image Url</th>
                                                <th>Description<span className="asterisk">*</span></th>
                                                <th>Short Description<span className="asterisk">*</span></th>
                                                <th>Weight<span className="asterisk">*</span></th>
                                                <th>Color</th>
                                                <th>Sequence<span className="asterisk">*</span></th>
                                                <th>Short URL<span className="asterisk">*</span>
                                                    {/* <Form.Text className="text-muted">eg: https://www.aawaz.com/showName</Form.Text> */}
                                                </th>
                                                {/* <th>Deep URL<span className="asterisk">*</span></th> */}
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {!this.state.isLoading ?
                                                Channel.map((show, index) =>

                                                    this.state.uniqueSlug === show.uniqueSlug ?
                                                        <tr key={index} style={{ backgroundColor: "#ededed" }}>


                                                            <td>{index + 1}</td>
                                                            <td><input type="text" name="title" value={editedChannel.title} autoComplete="off" onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td align="center">{this.state.featuredImg ? <Figure><Figure.Image src={this.state.readFeaturedImg} thumbnail /></Figure> : <Image thumbnail src={`${editedChannel.featured_image}?${imgHash}`} />}<label htmlFor="image-change1" title="Change Image"><Icons.Upload /></label><input type="file" id="image-change1" onChange={this.onFeaturedImageChange} style={{ display: "none" }} /></td>
                                                            <td><input type="text" placeholder="featured Image Url" name="featured_image_url" value={editedChannel.featured_image_url} onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td><input type="text" placeholder="Image Url" name="image_url" value={editedChannel.image_url} onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td align="center">{this.state.bannerImg ? <Figure><Figure.Image src={this.state.readBannerImg} thumbnail /></Figure> : <Image thumbnail src={`${editedChannel.banner_image}?${imgHash}`} />}<label htmlFor="image-change2" title="Change Image"><Icons.Upload /></label><input type="file" id="image-change2" onChange={this.onBannerImageChange} style={{ display: "none" }} /></td>
                                                            <td><input type="text" placeholder="banner Image Url" name="banner_image_url" value={editedChannel.banner_image_url} onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td><textarea value={editedChannel.description} name="description" onChange={this.editShowHandler.bind(this)}></textarea></td>
                                                            <td><textarea value={editedChannel.short_description} name="short_description" onChange={this.editShowHandler.bind(this)}></textarea></td>
                                                            <td><input type="number" name="weight" value={editedChannel.weight} min="1" autoComplete="off" style={numberStyle} onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td><ChromePicker color={editedChannel.color} onChange={this.colorPickerHandler.bind(this)} /></td>
                                                            <td><input type="number" name="sequence" value={editedChannel.sequence} min="1" autoComplete="off" style={numberStyle} onChange={this.editShowHandler.bind(this)}></input></td>
                                                            <td><input type="text" value={editedChannel.short_url || ""} name="short_url" autoComplete="off" onChange={this.editShowHandler.bind(this)}></input></td>
                                                            {/* <td><input type="text" value={editedShow.deep_url || ""} name="deep_url" autoComplete="off" onChange={this.editShowHandler.bind(this)}></input></td> */}
                                                            <td>
                                                                {this.state.addNewShow && !show.uniqueSlug ? <div><Button variant="outline-success" onClick={this.addNewShowHandler}>Add</Button>&nbsp;<Button variant="outline-danger" onClick={() => { if (window.confirm("Are you sure you wish to cancel adding new category?")) this.cancelAddNewShow() }}>Cancel</Button></div> : <div><Button variant="outline-info" onClick={this.updateShowHandler}>Update</Button>&nbsp;<Button variant="outline-warning" onClick={() => { if (window.confirm("Do you wish to cancel editing category? changes will be lost!")) this.cancelEditing() }}>Cancel</Button></div>}
                                                            </td>
                                                        </tr>
                                                        :
                                                        <tr key={index} onDoubleClick={this.toggleEdit.bind(this, show.uniqueSlug, show)}>
                                                            <td>{index + 1}</td>
                                                            {this.state.uniqueSlug === show.uniqueSlug ? <td><input type="text" placeholder="Add New Channel" name="title" value={show.title ? show.title : ""} autoComplete="off" onChange={this.editShowHandler.bind(this)}></input></td> : <td><input type="text" value={show.title} disabled ></input></td>}
                                                            <td align="center">{show.featuredImageUrl ? <Image src={`${show.featuredImageUrl}?${imgHash}`} thumbnail /> : <p>No Image</p>}</td>
                                                            <td><input type="text" placeholder="featured Image Url" name="featured_image_url" value={show.featuredImageUrl} disabled></input></td>
                                                            <td><input type="text" placeholder="Image Url" name="image_url" value={show.image_url} disabled></input></td>
                                                            <td align="center">{show.bannerImageUrl ? <Image src={`${show.bannerImageUrl}?${imgHash}`} thumbnail /> : <p>No Image</p>}</td>
                                                            <td><input type="text" placeholder="banner Image Url" name="banner_image_url" value={show.bannerImageUrl} disabled></input></td>
                                                            <td><textarea value={show.description ? show.description : ""} disabled ></textarea></td>
                                                            <td><textarea value={show.shortDescription ? show.shortDescription : ""} disabled></textarea></td>
                                                            <td><input type="number" value={show.weight ? show.weight : ""} style={numberStyle} disabled></input></td>
                                                            <td>
                                                                <svg width="20" height="20">
                                                                    <rect width="20" height="20" style={{ fill: show.color || "#ffffff", strokeWidth: 3, stroke: "rgb(0,0,0)" }} />
                                                                </svg>
                                                            </td>
                                                            <td><input type="number" value={show.sequence ? show.sequence : ""} min="1" disabled style={numberStyle} ></input></td>
                                                            <td><input type="text" value={show.shortUrl ? show.shortUrl : ""} disabled></input></td>
                                                            {/* <td><input type="url" value={show.deepUrl? show.deepUrl: ""} disabled></input></td>                                                     */}
                                                            <td>
                                                                {this.state.addNewShow && !show.uniqueSlug ? <Button variant="outline-danger" onClick={() => { if (window.confirm("Are you sure you wish to cancel adding new show?")) this.cancelAddNewShow() }}>Cancel</Button> : <Button variant="outline-danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete show "${show.title}"?`)) this.deleteShowHandler(show.uniqueSlug, show.title) }}>Delete</Button>}
                                                            </td>


                                                        </tr>
                                                )
                                                :
                                                <tr>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                    <td>{loader}</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {<ReactPaginate
                        previousLabel={'<'}
                        nextLabel={'>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.paginationCount}
                        onPageChange={this.handlePageClick}
                        containerClassName={'pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                        forcePage={this.state.currentPage - 1}
                    ></ReactPaginate>}
                </div>
            </Container>
        );
    }
}

export default ChannelSheet;
import React, { Component } from "react";
import axios from "axios";
import { Form, Button, Col, Figure, Row } from "react-bootstrap";
import { baseURL } from "../../../services/servicesPlatform";
import { createSection, getSection } from "../../../services/serviceSections";
import { getAllSectionTypes } from "../../../services/serviceSectionTypes";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import auth from "../../../services/authentication";
import { getShowsDropDowns, getShow } from "../../../services/serviceShows";
import { liveEpisodes } from "../../../services/serviceEpisodes";
import { getCategoryDropDowns } from "../../../services/serviceCategories";
import { getChannelDropdown } from "../../../services/serviceChannels";
import { allLiveShowDropdown } from "../../../services/serviceLive";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import update from "immutability-helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

class AddSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      color: "#000000",
      subtitle: "",
      sequence: null,
      section_type_slug: "",
      sectionTypeData: [],
      sequence: "",
      showdata: [],
      Showoptions: [],
      chadata: [],
      catdata: [],
      content_slug: "",
      short_url: "",
      thumbnail_Url: "",
      editedSection: {},
      childSlugs: [],
      dirty: false,
      endDatetime: "",
      startDatetime: "",
      is_recurring: false,
    };
  }

  handleDateChange = (date) => {
    this.setState({
      startDatetime: moment(date).format(),
      dateTime: date,
    });
  };
  handleEndDateChange = (date) => {
    this.setState({
      endDatetime: moment(date).format(),
      dateTime: date,
    });
  };

  handelrecurring = () => {
    this.setState({ is_recurring: !this.state.is_recurring });
  };
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-1fc08c"
    ) {
      //show
      getShowsDropDowns()
        .then((response) => {
          let shows = response.data;
          let showOptions = [];
          shows.forEach((data) => {
            showOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({ showOptions: showOptions, isShowLoading: false });
          // console.log('options: ', this.state.options)
        })
        .catch((err) => alert("Couldn't fetch shows data"));
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-612ab4"
    ) {
      getShowsDropDowns()
        .then((response) => {
          let shows = response.data;
          let showOptions = [];
          shows.forEach((data) => {
            showOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({ showOptions: showOptions, isShowLoading: false });
          // console.log('options: ', this.state.options)
        })
        .catch((err) => alert("Couldn't fetch shows data"));
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-4a137b"
    ) {
      console.log("epi");
      //episode
      getShowsDropDowns().then((response) => {
        let shows = response.data;
        let showOptions = [];
        shows.forEach((data) => {
          showOptions.push({ value: data.uniqueSlug, label: data.title });
        });
        this.setState({ showOptions: showOptions, isShowLoading: false });
        // console.log('options: ', this.state.options)
      });
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-304ebf"
    ) {
      //channel
      getChannelDropdown()
        .then((res) => {
          let channels = res.data;
          let channelOptions = [];
          channels.forEach((data) => {
            channelOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({
            ChannelOptions: channelOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("channel data fetch failed");
        });
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-c33acd"
    ) {
      //category
      getCategoryDropDowns()
        .then((res) => {
          let categories = res.data;
          let categoriesOptions = [];
          categories.forEach((data) => {
            categoriesOptions.push({
              value: data.uniqueSlug,
              label: data.name,
            });
          });
          this.setState({
            categoriesOptions: categoriesOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Category data fetch failed");
        });
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-20f9be"
    ) {
      // Live Shows
      allLiveShowDropdown()
        .then((res) => {
          let LiveShow = res.data;
          let LiveshowOptions = [];
          LiveShow.forEach((data) => {
            LiveshowOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({
            LiveshowOptions: LiveshowOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Live_Show data fetch failed");
        });
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-a72505"
    ) {
      // Live Episode
      liveEpisodes()
        .then((res) => {
          let LiveEpisode = res.data.results;
          let LiveEpisodeOptions = [];
          LiveEpisode.forEach((data) => {
            LiveEpisodeOptions.push({
              value: data.uniqueSlug,
              label: data.title,
            });
          });
          this.setState({
            LiveEpisodeOptions: LiveEpisodeOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Live Episode data fetch failed");
        });
    } else if (
      e.target.name == "section_type_slug" &&
      e.target.value == "set-0d263c"
    ) {
      // Fesrive section
      getShowsDropDowns().then((response) => {
        let shows = response.data;
        let showOptions = [];
        shows.forEach((data) => {
          showOptions.push({ value: data.uniqueSlug, label: data.title });
        });
        this.setState({ showOptions: showOptions, isShowLoading: false });
        // console.log('options: ', this.state.options)
      });
    }
  };
  selectHandler = (event, value) => {
    this.setState({ content_slug: event });
  };
  componentDidMount = () => {
    let uniqueSlug = this.props.match.params.id;
    if (uniqueSlug !== "" && uniqueSlug !== undefined) {
      getSection(uniqueSlug)
        .then((res) => {
          // console.log("section data", res.data);
          if (res.data.endDatetime !== null && res.data.endDatetime !== "") {
            this.setState({ is_recurring: true });
          }
          this.setState({
            ...res.data,
            section_type_slug: res.data.sectionType.uniqueSlug,
          });
          if (res.data.sectionType.uniqueSlug == "set-1fc08c") {
            //show
            getShowsDropDowns()
              .then((response) => {
                let shows = response.data;
                let showOptions = [];
                shows.forEach((data) => {
                  showOptions.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                let channelData = [];
                res.data.items.forEach((data) => {
                  channelData.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                this.setState({
                  showOptions: showOptions,
                  content_slug: channelData,
                  isShowLoading: false,
                });
              })
              .catch((err) => alert("Couldn't fetch shows data"));
          } else if (res.data.sectionType.uniqueSlug == "set-4a137b") {
            // console.log("epi");
            //episode
            getShowsDropDowns().then((response) => {
              let shows = response.data;
              let showOptions = [];
              shows.forEach((data) => {
                showOptions.push({ value: data.uniqueSlug, label: data.title });
              });
              let channelData = [];
              res.data.items.forEach((data) => {
                channelData.push({ value: data.uniqueSlug, label: data.title });
              });
              this.setState({
                showOptions: showOptions,
                content_slug: channelData,
                isShowLoading: false,
              });
            });
          } else if (res.data.sectionType.uniqueSlug == "set-304ebf") {
            //channel
            getChannelDropdown()
              .then((response) => {
                let channels = response.data;
                let channelOptions = [];
                channels.forEach((data) => {
                  channelOptions.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                let channelData = [];
                res.data.items.forEach((data) => {
                  channelData.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                this.setState({
                  ChannelOptions: channelOptions,
                  content_slug: channelData,
                  isShowLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                toast.error("channel data fetch failed");
              });
          } else if (res.data.sectionType.uniqueSlug == "set-c33acd") {
            //category
            getCategoryDropDowns()
              .then((response) => {
                let categories = response.data;
                let categoriesOptions = [];
                categories.forEach((data) => {
                  categoriesOptions.push({
                    value: data.uniqueSlug,
                    label: data.name,
                  });
                });
                let channelData = [];
                res.data.items.forEach((data) => {
                  channelData.push({
                    value: data.uniqueSlug,
                    label: data.name,
                  });
                });
                this.setState({
                  categoriesOptions: categoriesOptions,
                  content_slug: channelData,
                  isShowLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                toast.error("Category data fetch failed");
              });
          } else if (res.data.sectionType.uniqueSlug == "set-20f9be") {
            // Live Shows
            allLiveShowDropdown()
              .then((response) => {
                let LiveShow = response.data;
                let LiveshowOptions = [];
                LiveShow.forEach((data) => {
                  LiveshowOptions.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                let channelData = [];
                res.data.items.forEach((data) => {
                  channelData.push({
                    value: data.uniqueSlug,
                    label: data.title,
                  });
                });
                this.setState({
                  LiveshowOptions: LiveshowOptions,
                  content_slug: channelData,
                  isShowLoading: false,
                });
              })
              .catch((err) => {
                console.log(err);
                toast.error("Live_Show data fetch failed");
              });
          } else if (res.data.sectionType.uniqueSlug == "set-a72505") {
            // Live Episode
            liveEpisodes().then((response) => {
              let LiveEpisode = response.data.results;
              let LiveEpisodeOptions = [];
              LiveEpisode.forEach((data) => {
                LiveEpisodeOptions.push({
                  value: data.uniqueSlug,
                  label: data.title,
                });
              });
              let channelData = [];
              res.data.items.forEach((data) => {
                channelData.push({ value: data.uniqueSlug, label: data.title });
              });
              this.setState({
                LiveEpisodeOptions: LiveEpisodeOptions,
                content_slug: channelData,
                isShowLoading: false,
              });
            });
          } else if (res.data.sectionType.uniqueSlug === "set-0d263c") {
            console.log("festive section");
            getShowsDropDowns().then((response) => {
              let shows = response.data;
              let showOptions = [];
              shows.forEach((data) => {
                showOptions.push({
                  value: data.uniqueSlug,
                  label: data.title,
                });
              });
              let channelData = [];
              res.data.items.forEach((data) => {
                channelData.push({
                  value: data.uniqueSlug,
                  label: data.title,
                });
              });
              this.setState({
                showOptions: showOptions,
                content_slug: channelData,
                isShowLoading: false,
              });
            });
          }
        })

        .catch((err) => {
          console.log("section err", err);
        });
    }
    getAllSectionTypes()
      .then((response) => {
        console.log("section type", response.data.results);
        this.setState({ sectionTypeData: response.data.results });
        // window.location.reload(false);

        if (response.status === 400) {
          alert("Something went wrong, please try again");
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          let err = "";
          for (let [, value] of Object.entries(error.response.data)) {
            err = `${err} ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
      });
  };
  changeEpisode = (value) => {
    let Selectedvalue = value;
    let Slug =
      value !== null && value.length !== 0
        ? value[Selectedvalue.length - 1].value
        : null;
    if (Slug !== null) {
      getShow(Slug)
        .then((response) => {
          let data = response.data.episodes;
          console.log("data", data.episodes);
          if (data.length > 0) {
            let showOptions = [];
            data.forEach((data) => {
              showOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ options: showOptions, isShowLoading: false });
            // console.log('options: ', this.state.options)
          } else {
            this.setState({ data: [{ title: "No Data" }], isLoading: false });
          }
          //    this.setState({  epidata: response.data.episodes , isLoading: false })
        })

        .catch((err) => alert("Couldn't fetch episode data"));
      let prevEditedSection = this.state.editedSection;
      prevEditedSection.dirty = true;
      let newEditedSection = update(prevEditedSection, {
        childSlugs: { $set: value },
      });
      this.setState({ childSlugs: newEditedSection.childSlugs }, () =>
        console.log("childSlugs", newEditedSection.childSlugs)
      );
    } else {
      console.log("cannot get episodes");
    }
  };
  submitHandler = (e) => {
    e.preventDefault();
    // console.log(this.state)
    this.setState({ isLoading: true });
    let childSlugValue = JSON.stringify(this.state.childSlugs);
    let childSlugs = childSlugValue;
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "dirty":
        // case "is_recurring":
        case "sectionType":
        case "parentSlugs":
        case "startDatetime":
          if (this.state.is_recurring === true) {
            formData.append(`${key}`, value);
          }
          break;
        case "endDatetime":
          if (this.state.is_recurring === true) {
            formData.append(`${key}`, value);
          }
          break;
        case "content_slug":
          if (this.state.content_slug != "") {
            let Section = this.state.content_slug;

            let val1 = Section.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          }
          break;
        case "childSlugs":
          formData.append(`${key}`, childSlugs);
          break;
        case "showOptions":
        default:
          formData.append(`${key}`, value);
      }
    }
    for (var [key, value] of formData.entries()) {
      // if (value === '' || value === undefined || value === {}) {
      //     this.setState({ isLoading: false })
      //     // return toast.error(`${key} is required!`)
      // }
      if (key == "section_type_slug" && value === "") {
        this.setState({ isLoading: false });
        return toast.error(` section Type is required!`);
      }
      console.log(key + ": " + value);
    }

    // axios({
    // url: `${baseURL}/platforms/`,
    // method: "POST",
    // headers:{
    //     "Content-Type": "multipart/form-data"
    // },
    // data: formData,
    // auth: auth
    // })
    createSection(formData)
      .then((response) => {
        if (response.status === 201) {
          toast.success(
            `New section ${response.data.name} created successfully.`
          );
          this.setState({ isLoading: true });
          // window.location.reload(false);
        }
        // if(response.status === 400){
        // alert('Something went wrong, please try again')
        // this.setState({isLoading: false})
        // }
        this.setState({
          isLoading: false,
          name: "",
          color: "#000000",
          subtitle: "",
          sequence: "",
          section_type_slug: "",
          content_slug: "",
          short_url: "",
          thumbnail_Url: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };
  updateSectionHandler = () => {
    // this.showLoader()
    let contentSlug = this.state.content_slug.map((data) => {
      return data.value;
    });
    let content_slug = contentSlug.join();
    console.log("content_slug", content_slug);
    let childSlugValue = JSON.stringify(this.state.childSlugs);
    let childSlugs = childSlugValue;
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "dirty":
        case "sectionType":
        case "parentSlugs":
        case "showOptions":
        case "thumbnail_url":
          if (key === "thumbnail_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "thumbnail_url" && value !== "") {
            formData.append(`${key}`, value);
          }
          break;
        case "startDatetime":
          if (this.state.is_recurring) {
            formData.append(`${key}`, value);
          }
          break;
        case "endDatetime":
          if (this.state.is_recurring) {
            formData.append(`${key}`, value);
          }
          break;
        case "short_url":
          if (key === "short_url" && value == undefined) {
            formData.append(`${key}`, "");
          } else if (key === "short_url" && value !== "") {
            formData.append(`${key}`, value);
          }
          break;
        case "content_slug":
          formData.append(`${key}`, content_slug);
          break;
        case "childSlugs":
          formData.append(`${key}`, childSlugs);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }

    axios({
      method: "PATCH",
      url: `${baseURL}/section/${this.state.uniqueSlug}/`,
      headers: {
        "Content-Type": "multipart/form-data",
      },
      auth: auth,
      data: formData,
    })
      .then((res) => {
        toast.success(`Section "${res.data.name}" updated!`);
        this.setState({
          isLoading: false,
          name: "",
          color: "#000000",
          subtitle: "",
          sequence: "",
          section_type_slug: "",
          content_slug: "",
          short_url: "",
          thumbnail_Url: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          let err = "";
          for (let [key, value] of Object.entries(error.response.data)) {
            err = `${key}: ${value[0]}. `;
          }
          toast.error(err);
          this.setState({ isLoading: false });
        }
        console.log(error);
      });
  };

  render() {
    const {
      sequence,
      name,
      short_url,
      color,
      subtitle,
      section_type_slug,
      thumbnail_Url,
      showdata,
      chadata,
      epidata,
      catdata,
      content_slug,
      sectionTypeData,
      show_val,
    } = this.state;

    console.log(this.state.content_slug);
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center"
        />
        {!this.props.match.params.id ? (
          <h2>Add New Section</h2>
        ) : (
          <h2>Update Section</h2>
        )}

        <Form onSubmit={this.submitHandler}>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>
                  Section Name: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Section Name here"
                  name="name"
                  value={name}
                  onChange={this.changeHandler}
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Group>
              <Form.Row>
                <Col>
                  <Form.Label>Color: </Form.Label>
                  <Form.Control
                    type="color"
                    placeholder="Enter Section Color here"
                    name="color"
                    value={color}
                    onChange={this.changeHandler}
                  />
                </Col>

                <Col>
                  <Form.Label>
                    Subtitle: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Section Subtitle here"
                    name="subtitle"
                    value={subtitle}
                    onChange={this.changeHandler}
                  />
                </Col>
                <Col>
                  <Form.Label>Short URL: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Short URL here"
                    name="short_url"
                    value={short_url}
                    onChange={this.changeHandler}
                  />
                </Col>
                <Col>
                  <Form.Label>
                    sequence: <span className="asterisk"> *</span>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="sequence"
                    min="1"
                    name="sequence"
                    value={sequence}
                    onChange={this.changeHandler}
                  />
                </Col>
                <Col>
                  <Form.Label>Thumbnai URL: </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Thumbnai URL here"
                    name="thumbnail_Url"
                    value={thumbnail_Url}
                    onChange={this.changeHandler}
                  />
                </Col>
              </Form.Row>
            </Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>
                  Section Type: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="section_type_slug"
                  styles={{ control: (styles) => ({ ...styles, width: 300 }) }}
                  value={section_type_slug}
                  onChange={this.changeHandler}
                >
                  {sectionTypeData.length == 0 ? (
                    <option defaultValue value="">
                      Loading...
                    </option>
                  ) : (
                    <option defaultValue value="">
                      Select Action
                    </option>
                  )}
                  {sectionTypeData.map((data, index) => (
                    <option key={index} value={data.uniqueSlug}>
                      {data.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col>
                {section_type_slug === "set-4a137b" ? (
                  <>
                    <Form.Label>Show name</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.childSlugs}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="childSlugs"
                      onChange={this.changeEpisode}
                      isLoading={this.state.isShowLoading}
                      options={this.state.showOptions}
                    />
                    <p>Episode</p>

                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.options}
                    />
                  </>
                ) : // channel
                section_type_slug === "set-304ebf" ? (
                  <>
                    <Form.Label>Channel name</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.ChannelOptions}
                    />
                  </>
                ) : // category
                section_type_slug === "set-c33acd" ? (
                  <>
                    <Form.Label>Category name</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.categoriesOptions}
                    />
                  </>
                ) : // show
                section_type_slug === "set-1fc08c" ? (
                  <>
                    <Form.Label>Show name</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.showOptions}
                    />
                  </>
                ) : // Live show
                section_type_slug === "set-20f9be" ? (
                  <>
                    <Form.Label> Live Show </Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.LiveshowOptions}
                    />
                  </>
                ) : // LiveEpisode
                section_type_slug === "set-a72505" ? (
                  <>
                    <Form.Label> Live Episodes</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.LiveEpisodeOptions}
                    />
                  </>
                ) : // channel shows
                section_type_slug === "set-612ab4" ? (
                  <>
                    <Form.Label>Show name</Form.Label>
                    <Select
                      isMulti
                      defaultValue
                      value={this.state.content_slug}
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      name="content_slug"
                      onChange={this.selectHandler}
                      isLoading={this.state.isShowLoading}
                      options={this.state.showOptions}
                    />
                  </>
                ) : // Festive section
                section_type_slug === "set-0d263c" ? (
                  <Form.Group>
                    <Form.Row>
                      <Col>
                        <Form.Label>Show name </Form.Label>
                        <Select
                          isMulti
                          defaultValue
                          value={this.state.content_slug}
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          name="content_slug"
                          onChange={this.selectHandler}
                          isLoading={this.state.isShowLoading}
                          options={this.state.showOptions}
                        />
                      </Col>
                      <Col>
                        <Form.Label>Show name For Episode</Form.Label>
                        <Select
                          isMulti
                          defaultValue
                          value={this.state.childSlugs}
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          name="childSlugs"
                          onChange={this.changeEpisode}
                          isLoading={this.state.isShowLoading}
                          options={this.state.showOptions}
                        />
                        <p>Episode</p>

                        <Select
                          isMulti
                          defaultValue
                          value={this.state.content_slug}
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          name="content_slug"
                          onChange={this.selectHandler}
                          isLoading={this.state.isShowLoading}
                          options={this.state.options}
                        />
                      </Col>
                    </Form.Row>
                  </Form.Group>
                ) : null}
              </Col>
              <Col>
                <div style={{ display: "flex" }}>
                  <Form.Label>
                    Is Recurring: <span className="asterisk"> </span>
                  </Form.Label>{" "}
                  <br></br>
                  <Form.Control
                    style={{
                      width: "14px",
                      height: "27px",
                      marginLeft: "12px",
                    }}
                    type="checkbox"
                    name="is_recurring"
                    checked={this.state.is_recurring}
                    onClick={this.handelrecurring}
                  />
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Label>Start Date :</Form.Label>
                  <DatePicker
                    name="startDatetime"
                    selected={this.state.dateTime}
                    value={this.state.startDatetime}
                    onChange={this.handleDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    autocomplete="off"
                    disabled={!this.state.is_recurring}
                    popperPlacement="top-start"
                  ></DatePicker>
                </div>
              </Col>
              <Col>
                <div>
                  <Form.Label> End Date :</Form.Label>
                  <DatePicker
                    name="endDatetime"
                    selected={this.state.dateTime}
                    value={this.state.endDatetime}
                    onChange={this.handleEndDateChange}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={10}
                    timeCaption="time"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    autocomplete="off"
                    disabled={!this.state.is_recurring}
                    popperPlacement="top-start"
                  ></DatePicker>
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
          {!this.props.match.params.id ? (
            <Button type="submit" variant="info">
              Publish
            </Button>
          ) : (
            <Button onClick={this.updateSectionHandler} variant="info">
              Update
            </Button>
          )}
        </Form>
      </div>
    );
  }
}

export default AddSection;

import React, { Component } from "react";
import { Form, Col, Button, Figure } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import {
  CreatePlans,
  UpdatePlans,
} from "../../../../services/serviceSubscription";

class AddPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      description: "",
      amount: 0,
      productUuid: "",
      interval: "",
      intervalCount: 0,
      isLoading: false,
      intervalData: ["day", "week", "month", "year"],
    };
  }
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  componentDidMount() {
    if (this.props.isEdit) {
      this.setState({
        ...this.props.data,
        productUuid: this.props.id,
        amount: this.props.data.amount / 100,
      });
    } else {
      this.setState({
        name: "",
        description: "",
        amount: 0,
        productUuid: this.props.id,
        interval: "",
        intervalCount: 0,
        isLoading: false,
        intervalData: ["day", "week", "month", "year"],
      });
    }
  }
  submitHandler = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "name":
          formData.append(`${key}`, value);
          break;
        case "description":
          formData.append(`${key}`, value);
          break;
        case "amount":
          formData.append(`${key}`, value * 100);
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    CreatePlans(formData)
      .then((res) => {
        this.props.handleModalClose();
        this.props.fetch();
        toast.success("plan update sucessfully!");
        this.props.fetch();
      })
      .catch((err) => {
        toast.error("plan create failed!");
      });
  };
  updateHandler = (e) => {
    e.preventDefault();
    // this.setState({ isLoading: true });
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "name":
          formData.append(`${key}`, value);
          break;
        case "description":
          formData.append(`${key}`, value);
          break;
        default:
        //   formData.append(`${key}`, value);
      }
    }
    UpdatePlans(this.state.uuid, formData)
      .then((res) => {
        this.props.handleModalClose();
        this.props.fetch();
        toast.success("plan update sucessfully!");
        this.setState({
          name: "",
          description: "",
          amount: 0,
          productUuid: "",
          interval: "",
          intervalCount: 0,
          isLoading: false,
          intervalData: ["day", "week", "month", "year"],
        });
      })
      .catch((err) => {
        toast.error("plan update failed!");
      });
  };
  render() {
    let { name, description, amount, intervalCount, intervalData, interval } =
      this.state;

    return (
      <div>
        <ToastContainer />
        <Form onSubmit={this.submitHandler}>
          <Form.Group>
            <Col>
              <Form.Label>
                Plan Name: <span className="asterisk"> *</span>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Plan Name here"
                name="name"
                value={name}
                onChange={this.changeHandler}
                maxLength="255"
                required
              />
            </Col>
            <Col>
              <Form.Label>
                Plan description: <span className="asterisk"> *</span>
              </Form.Label>
              <Form.Control
                as="textarea"
                rows="4"
                placeholder="Enter Plan description here"
                name="description"
                value={description}
                onChange={this.changeHandler}
              />
            </Col>
            <Col>
              <Form.Label>
                Plan Amount:(Rs) <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="amount"
                value={amount}
                onChange={this.changeHandler}
              />
            </Col>
            <Col>
              <Form.Label>
                interval Count: <span className="asterisk">*</span>
              </Form.Label>
              <Form.Control
                type="number"
                name="intervalCount"
                value={intervalCount}
                onChange={this.changeHandler}
              />
            </Col>
            <Col>
              <Form.Label>
                Interval: <span className="asterisk"> *</span>
              </Form.Label>
              <Form.Control
                as="select"
                name="interval"
                value={interval}
                onChange={this.changeHandler}
              >
                <option defaultValue value="">
                  Select a Interval
                </option>
                {intervalData.map((data, index) => (
                  <option key={index} value={data}>
                    {data}
                  </option>
                ))}
              </Form.Control>
            </Col>
          </Form.Group>
          {this.props.isEdit ? (
            <Button onClick={this.updateHandler}>Update</Button>
          ) : (
            <Button type="submit" variant="info">
              Publish
            </Button>
          )}
        </Form>
      </div>
    );
  }
}

export default AddPlan;

import React, { Component } from 'react';
import { Table, Image, Button, Modal } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
// import thumbnail from '../../../images/thumbnail.webp'

import { removeBanner } from '../../../services/serviceBanner';

class BannerPlatform extends Component {
    state = {
        name: '',
        short_url: '',
        editBanner: false
    }
    editBanner = banner => {
        this.toggleBannerEditModal()
        // console.log("banner: ", banner)
        this.setState({
            name: banner.name,
            short_url: banner.shortUrl || 'NA'
        })
    }

    toggleBannerEditModal = () => {
        this.setState({ editBanner: !this.state.editBanner })
    }

    onChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    onBannerDeleteHandler = async (id, name) => {
        await removeBanner(id)
        .then(res => {
            console.log(res)
            toast.success(`Banner ${name} deleted successfully!`)
            this.props.removeBanner(id)
        })
        .catch(err => {
            console.log(`Banner ${name} delete error`, err)
            toast.error(`Banner ${name} delete failed!`)
        })
    }

    render() {
        const {name, short_url} = this.state;
        let banner = this.props.banners.filter(banner => banner.platform !== null && banner.platform.name === this.props.platformName)
        // let imgHash = Date.now();
        let tableData = banner.map((banner, index) =>
            <tr key={banner.uniqueSlug}>
                <td>{index + 1}</td>
                {/* <td><Image src={banner.backgroundImageUrl ? banner.backgroundImageUrl : thumbnail} width="100px" thumbnail></Image></td> */}
                <td>{banner.name}</td>
                <td>{banner.shortUrl}</td>
                <td><Button variant="primary" onClick={() => this.editBanner(banner)}>Edit</Button>
                    <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete banner "${banner.name}"?`)) this.onBannerDeleteHandler(banner.uniqueSlug, banner.name) }}>Delete</Button>
                </td>
            </tr>
        )
        return (
            <div>
                <ToastContainer position="top-right"/>
                {/* Edit banner modal */}
                <Modal show={this.state.editBanner} onHide={this.toggleBannerEditModal} dialogClassName="modal-90w">
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Banner {name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        short url: {short_url}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.toggleBannerEditModal}>Close</Button>
                        <Button variant="primary" onClick={this.toggleBannerEditModal}>Save Changes</Button>
                    </Modal.Footer>
                </Modal>

                {/* Default page */}
                <Table responsive>
                    <thead>
                        <tr>
                            <th>Sr. no</th>
                            <th>Banner Image</th>
                            <th>Banner Name</th>
                            <th>Short URL</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableData.length === 0 ?
                                <tr>
                                    <td>No data</td>
                                    <td>No data</td>
                                    <td>No data</td>
                                    <td>No data</td>
                                    <td>No data</td>
                                </tr>
                                :
                                tableData
                        }
                    </tbody>
                </Table>
            </div>
        )
    }
}


export default BannerPlatform;
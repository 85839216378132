import React, { Component } from 'react';
import { Container, Button, Modal, Tab, Tabs } from 'react-bootstrap'
import BannerPlatform from './BannerPlatform'
import Bannerv2 from './AllBannerv2'
import { getAllPlatform } from '../../../services/servicesPlatform'
import { getAllBanner, getAllBannerV2 } from '../../../services/serviceBanner';

import Loader from 'react-loader-spinner';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class AddBanner extends Component {

  state = {
    next: "not null",
    totalBanner: 0,
    paginationCount: 0,
    currentPage: 1,
    activeVersionTab: "",
    platform: [],
    banners: [],
    platformName: "android",
    isLoading: false,
    filter: "True"
  }

  componentDidMount() {
    this.setState({ isLoading: true })
    getAllPlatform()
      .then(response => {
        this.setState({ platform: response.data, isLoading: false })
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
        toast.error("Couldn't fetch banner platforms!");
      });
  }
  platformHandler = (name) => {
    console.log("name id platform handler: ", name)
    this.setState({ platformName: name }, () => console.log(this.state.platformName))
  }
  removeBanner = (id) => {
    let newBanner = this.state.banners.filter(banner => banner.uniqueSlug !== id)
    // console.log("New banners: ", newBanner)
    this.setState({ banners: newBanner })
  }
  fetchBannerdata = (page = 1, filter) => {
    getAllBannerV2(page, filter)
      .then((response) => {
        this.setState({ banners: response.data.results, next: response.data.next, paginationCount: Math.ceil(response.data.count / 20), totalBanner: response.data.count, isLoading: false })
        // console.log("banner v2",)
      })
      .catch(error => {
        console.log(error)
        this.setState({ isLoading: false })
        toast.error("Couldn't banner data!");
      });
  }
  selectBannerVersion = (key) => {
    this.setState({ activeVersionTab: key, isLoading: true }, () => {
      switch (key) {
        case "v1":
          getAllBanner()
            .then((response) => {
              this.setState({ banners: response.data, isLoading: false })
            })
            .catch(error => {
              console.log(error)
              this.setState({ isLoading: false })
              toast.error("Couldn't banner data!");
            });
          break;
        default:
          this.fetchBannerdata(this.state.currentPage)
      }

    })
  }
  handlePageClick = (data) => {
    // this.showLoader()
    let selected = data.selected + 1;
    this.props.history.replace("?page=" + selected)
    this.setState({ currentPage: selected }, () => this.fetchBannerdata(this.state.currentPage, this.state.filter))

  }
  HandelFilter = (e) => {
    this.setState({ isLoading: true, filter: e.target.value, currentPage: 1 })

    this.fetchBannerdata(1, e.target.value)
    // getAllBannerV2(1, e.target.value)
    //   .then((response) => {
    //     this.setState({ banners: response.data.results, next: response.data.next, paginationCount: Math.ceil(response.data.count / 20), totalBanner: response.data.count, isLoading: false })
    //     // console.log("banner v2",)
    //   })
    //   .catch(err => {
    //     console.log("err", err)
    //   })
  }
  render() {
    // const platform = this.state.platform
    const { banners, platform, isLoading, activeVersionTab, next, paginationCount, currentPage, totalBanner } = this.state
    return (
      <Container fluid>
        <h1>All Banner </h1>
        {!isLoading ?
          <Tabs defaultActiveKey={activeVersionTab} onSelect={(k) => this.selectBannerVersion(k)}>
            <Tab eventKey="v1" title="Version 1">
              {!isLoading ?
                <div>
                  {platform.length !== 0 && banners.length > 0 && banners[0].name !== undefined ?
                    <Tabs id="uncontrolled-tab-example">
                      {
                        platform.map(data =>
                          <Tab eventKey={data.name} key={data.uniqueSlug} title={data.name}>
                            <BannerPlatform removeBanner={this.removeBanner} platformName={data.name} banners={banners} next={next} />
                          </Tab>
                        )
                      }
                    </Tabs>
                    :
                    <p>No banner platform data present! try refreshing...</p>}
                </div>
                :
                <Container style={{ textAlign: "center" }}>
                  <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />
                </Container>
              }
            </Tab>
            <Tab eventKey="v2" title="Version 2" fluid>
              {!isLoading ?
                <div>
                  {banners.length > 0 ?
                    <Bannerv2 removeBanner={this.removeBanner} banners={banners} next={next} paginationCount={paginationCount} currentPage={currentPage} totalBanner={totalBanner} handlePageClick={this.handlePageClick} HandelFilter={this.HandelFilter} filter={this.state.filter} />
                    :
                    <p>No banner platform data present! try refreshing...</p>}
                </div>
                :
                <Container style={{ textAlign: "center" }}>
                  <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />
                </Container>
              }
            </Tab>
          </Tabs>
          :
          <Container style={{ textAlign: "center" }}>
            <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />
          </Container>
        }

      </Container>
    );
  }
}

export default AddBanner;
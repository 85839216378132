import http from '../http-common'
import auth from './authentication'

const baseURL = "https://preprod.aawaz.com/api";

const getAllBannerTags = () => {
    return http.get(`${baseURL}/banner-tags/?limit=200`, { auth: auth });
};

const getBannerTags = id => {
    return http.get(`${baseURL}/banner-tags/${id}/`, { auth: auth });
}

const createBannerTags = data => {
    return http.post(`${baseURL}/banner-tags/`, { auth: auth, data: data });
}

const updateBannerTags = (id, data) => {
    return http.patch(`${baseURL}/banner-tags/${id}/`, { auth: auth, data: data });
}

const removeBannerTags = id => {
    return http.delete(`${baseURL}/banner-tags/${id}`, { auth: auth });
}


export {
    getAllBannerTags, getBannerTags, createBannerTags, updateBannerTags, removeBannerTags, baseURL
}
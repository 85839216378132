import React, { Component } from 'react'
import { Form, Col, Button, Row, Table, Container, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ListRj, deleteRj } from '../../../services/serviceLive'
import LiveShowDash from '../LiveShowDash/liveShowPage'
import RjProfile from '../rjProfile/rjProfilePage'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import logo from '../../../images/aawaz-logo.png';
import ReactPaginate from 'react-paginate';
// import rjProfilePage from '../rjProfile/rjProfilePage';
import ContentLoader from "react-content-loader";
import Sidebar from '../Sidebar/Sidebar'

class RjList extends Component {
    constructor(props) {
        super(props);
        this.state = {

            next: "",
            totalSections: 0,
            paginationCount: 0,
            currentPage: 1,
            RjLists: [],
            Rjedit: false,
            editedData: {},
            isLoading: true
        };
    }

    componentDidMount() {
        this.fetchRjListsData()
    }
    fetchRjListsData = (page) => {
        ListRj(page)
            .then(response => {
                console.log(response.data.results)
                this.setState({ RjLists: response.data.results, next: response.data.next, paginationCount: Math.ceil(response.data.count / 10), Items: response.data.results.items, totalSections: response.data.results.length, isLoading: false })
            })

            .catch(error => {
                // if (error.response.status === 400) {
                //   let err = "";
                //   for (let [key, value] of Object.entries(error.response.data)) {
                //     err = `${key}: ${value[0]}. `
                //   }
                //   toast.error(err)
                //   this.hideLoader()
                // }
                console.log(error)
            })
    }
    handlePageClick = (data) => {
        // this.showLoader()
        console.log("page", data)
        let selected = data.selected + 1;
        console.log(selected)
        this.props.history.replace("?page=" + selected)
        this.setState({ currentPage: selected, isLoading: true }, () => this.fetchRjListsData(this.state.currentPage))

    }
    onDeleteRj = (id, name) => {
        deleteRj(id)
            .then(response => {
                toast.success(`${name}deleted successfully`)
                this.fetchRjListsData(this.state.currentPage)
            })

            .catch(error => {
                if (error.response.status === 404) {
                    let err = "";
                    for (let [key, value] of Object.entries(error.response.data)) {
                        err = `${key} ${value}. `
                    }
                    toast.error(err)
                }
                console.log(error)
            })
    }
    toggleLiveShowEditModal = () => {
        this.setState({ Rjedit: !this.state.Rjedit })
    }
    editRj = (data) => {
        this.toggleLiveShowEditModal()
        this.setState({ editedData: data })
    }

    render() {
        const loader = <ContentLoader viewBox="0 0 200 70" backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>
        return (
            <div>
                <ToastContainer
                    autoClose={5000}
                    hideProgressBar={false}
                    position="top-center" />
                <Container>
                    <Modal size="xl" show={this.state.Rjedit} onHide={this.toggleLiveShowEditModal} >
                        <Modal.Header closeButton>
                            <Modal.Title>Edit Rj</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <RjProfile editedData={this.state.editedData} upadte="true" />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.toggleLiveShowEditModal}>Close</Button>
                            {/* <Button variant="primary" onClick={this.updateHandler}>Save Changes</Button> */}
                        </Modal.Footer>
                    </Modal>

                    <Row>
                        <Sidebar />
                        <Col className="centerAlign">
                            <Table responsive hover >
                                <thead>
                                    <tr key="header">
                                        <th scope="col">Sr.no</th>
                                        {/* <th scope="col">Featured Image</th> */}
                                        <th scope="col">Rj Name </th>
                                        <th scope="col">Actions</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !this.state.isLoading ?
                                            this.state.RjLists.map((RjLists, index) =>
                                                <tr key={index}>
                                                    <td key={index}>{index + 1}</td>
                                                    <td>{RjLists.name}</td>
                                                    <td>
                                                        <Button variant="primary" onClick={() => this.editRj(RjLists)}>Edit</Button>
                                                        <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete show ${RjLists.name}?`)) this.onDeleteRj(RjLists.uniqueSlug, RjLists.name) }}>Delete</Button>
                                                    </td>

                                                </tr>
                                            ) :

                                            <tr>
                                                <td>{loader}</td>
                                                <td>{loader}</td>
                                            </tr>
                                    }



                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.paginationCount}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                    forcePage={this.state.currentPage - 1}
                ></ReactPaginate>
            </div>
        )
    }
}

export default RjList;
import React, { Component } from "react";
import { Form, Button, Col, Figure, Row } from "react-bootstrap";
import { getShowsDropDowns, getShow } from "../../../services/serviceShows";
import { getTag } from "../../../services/serviceTag";
import { liveEpisodes } from "../../../services/serviceEpisodes";
import { getCategoryDropDowns } from "../../../services/serviceCategories";
import { getChannelDropdown } from "../../../services/serviceChannels";
import { allLiveShowDropdown } from "../../../services/serviceLive";
import { ToastContainer, toast } from "react-toastify";
import {
  createdashboardV3,
  updatedashboardV3,
  getdashboardV3Byid,
} from "../../../services/serviceSections";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

export default class AddDahboardV3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      subtitle: "",
      color: "",
      content_type: "",
      auto_generation_method: "",
      content_slug: "",
      is_active: true,
      background_image: "",
      thumbnail_image: "",
      short_url: "",
      sequence: "",
      ContentData: [],
      backgroundImageView: "",
      ThumbnailImageView: "",
      contentTypeData: [
        "SHOW",
        "EPISODE",
        "CATEGORY_SHOWS",
        "CHANNEL_SHOWS",
        "TAG_SHOWS",
      ],
      AutoGenerationData: ["LATEST", "POPULAR", "CUSTOM"],
      show_slug: [],
      showDataForEpisode: [],
    };
  }
  // Handel Is Active
  handelIsActive = () => {
    this.setState({ is_active: !this.state.is_active });
  };
  // HANDEL FOR MULTI SELECT
  selectHandler = (event, value) => {
    this.setState({ content_slug: event });
  };
  // HANDEL CHNAGES AND API CALL FOR CONTANT TYPE
  changeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value, isShowLoading: true });
    this.HandelApiContantType(e.target.name, e.target.value);
  };
  //  HANDEL API CALL FOR
  HandelApiContantType = (Type, Vlaue) => {
    if (Type == "content_type" && Vlaue == "SHOW") {
      //  *****************SHOW CONTANT******************************
      getShowsDropDowns()
        .then((response) => {
          let shows = response.data;
          let showOptions = [];
          shows.forEach((data) => {
            showOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({ ContentData: showOptions, isShowLoading: false });
        })
        .catch((err) => alert("Couldn't fetch shows data"));
    } else if (Type == "content_type" && Vlaue == "EPISODE") {
      //***************** EPISODE CONTANT ***************************

      getShowsDropDowns()
        .then((response) => {
          let shows = response.data;
          let showOptions = [];
          shows.forEach((data) => {
            showOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({
            showDataForEpisode: showOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => alert("Couldn't fetch shows data"));
    } else if (Type == "content_type" && Vlaue == "TAG_SHOWS") {
      // ************************ Tag Contant ***************************
      getTag()
        .then((res) => {
          let tags = res.data;
          let tagsOptions = [];
          tags.forEach((data) => {
            tagsOptions.push({
              value: data.uniqueSlug,
              label: data.name,
            });
          });
          this.setState({
            ContentData: tagsOptions,
            isShowLoading: false,
          });
        })
        .then((err) => {
          console.log(err);
        });
    } else if (Type == "content_type" && Vlaue == "CATEGORY_SHOWS") {
      //***************CATEGORY_SHOWS ********************
      getCategoryDropDowns()
        .then((res) => {
          // let categories = res.data;
          // let categoriesOptions = [];
          // categories.forEach((data) => {
          //   categoriesOptions.push({
          //     value: data.uniqueSlug,
          //     label: data.name,
          //   });
          // });
          this.setState({
            ContentData: res.data,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("Category data fetch failed");
        });
    } else if (Type == "content_type" && Vlaue == "CHANNEL_SHOWS") {
      // ******************************CHANNEL_SHOWS ****************************
      getChannelDropdown()
        .then((res) => {
          let channels = res.data;
          let channelOptions = [];
          channels.forEach((data) => {
            channelOptions.push({
              uniqueSlug: data.uniqueSlug,
              name: data.title,
            });
          });
          this.setState({
            ContentData: channelOptions,
            isShowLoading: false,
          });
        })
        .catch((err) => {
          console.log(err);
          toast.error("channel data fetch failed");
        });
    }
  };
  onbackgroundImageChange = (event) => {
    this.setState({ backgroundImageView: undefined, background_image: "" });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ background_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ backgroundImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  onThubmnailImageChange = (event) => {
    this.setState({ ThumbnailImageView: undefined, thumbnail_image: "" });
    if (event.target.files[0]) {
      let reader = new FileReader();
      this.setState({ thumbnail_image: event.target.files[0] });
      reader.onload = (e) => {
        this.setState({ ThumbnailImageView: e.target.result });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  changeEpisode = (value) => {
    // console.log("changeEpisode", value);
    this.setState({ show_slug: value, isShowLoading: true, isLoading: false });
    let Selectedvalue = value;
    let Slug =
      value !== null && value.length !== 0
        ? value[Selectedvalue.length - 1].value
        : null;
    // console.log("Slug", Slug);
    if (Slug !== null) {
      getShow(Slug)
        .then((response) => {
          let data = response.data.episodes;
          if (data.length > 0) {
            let showOptions = [];
            data.forEach((data) => {
              showOptions.push({ value: data.uniqueSlug, label: data.title });
            });
            this.setState({ ContentData: showOptions, isShowLoading: false });
          } else {
            this.setState({
              ContentData: [{ title: "No Data" }],
              isShowLoading: false,
            });
          }
        })
        .catch((err) => alert("Couldn't fetch episode data"));
    } else {
      console.log("cannot get episodes");
    }
  };

  submitHandler = (e) => {
    e.preventDefault();

    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "contentTypeData":
        case "ThumbnailImageView":
        case "backgroundImageView":
        case "ContentData":
        case "AutoGenerationData":
        case "content_slug":
          if (Array.isArray(this.state.content_slug)) {
            let val1 = this.state.content_slug.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          } else {
            formData.append(`${key}`, value);
          }

          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    createdashboardV3(formData)
      .then((res) => {
        this.setState({
          title: "",
          subtitle: "",
          color: "",
          content_type: "",
          auto_generation_method: "",
          content_slug: "",
          is_active: true,
          background_image: "",
          thumbnail_image: "",
          short_url: "",
          sequence: "",
          ContentData: [],
          backgroundImageView: "",
          ThumbnailImageView: "",
        });
        toast.success(`dashboard  v3 ${res.title} created sucessfully`);
      })
      .catch((err) => {
        toast.error(`dashboard  v3  created failed`);
        console.log(err);
      });
  };

  UpdateDashboard = (e) => {
    e.preventDefault();
    let formData = new FormData();
    for (let [key, value] of Object.entries(this.state)) {
      switch (key) {
        case "contentTypeData":
        case "ThumbnailImageView":
        case "backgroundImageView":
        case "ContentData":
        case "AutoGenerationData":
        case "sequence":
          break;
        case "content_slug":
          if (Array.isArray(this.state.content_slug)) {
            let val1 = this.state.content_slug.map((data) => {
              return data.value;
            });

            let val = val1.toString();
            formData.append(`${key}`, val);
          } else {
            formData.append(`${key}`, value);
          }
          break;
        default:
          formData.append(`${key}`, value);
      }
    }
    updatedashboardV3(this.props.data, formData)
      .then((res) => {
        this.setState({
          title: "",
          subtitle: "",
          color: "",
          content_type: "",
          auto_generation_method: "",
          content_slug: "",
          is_active: true,
          background_image: "",
          thumbnail_image: "",
          short_url: "",
          sequence: "",
          ContentData: [],
          backgroundImageView: "",
          ThumbnailImageView: "",
        });
        this.props.toggleDashboardEditModal();
        this.props.fetchData();
        toast.success(`dashboard  v3 ${res.data.title} updated sucessfully`);
      })
      .catch((err) => {
        toast.error(`dashboard v3 updated failed`);
        console.log(err);
      });
  };
  componentDidMount = () => {
    if (this.props.data) {
      getdashboardV3Byid(this.props.data)
        .then((res) => {
          console.log(res.data);

          this.setState({
            title: res.data.title,
            subtitle: res.data.subtitle,
            color: res.data.color,
            content_type: res.data.contentType,
            auto_generation_method: res.data.autoGenerationMethod,
            is_active: res.data.isActive,
            backgroundImageView: res.data.backgroundImageUrl,
            ThumbnailImageView: res.data.thumbnailImageUrl,
            short_url: res.data.shortUrl,
            sequence: res.data.sequence,
          });
          let ConatntOptions = [];
          res.data.content.forEach((data) => {
            ConatntOptions.push({ value: data.uniqueSlug, label: data.title });
          });
          this.setState({ content_slug: ConatntOptions });
          this.HandelApiContantType("content_type", res.data.contentType);

          if (res.data.contentType === "EPISODE") {
            let showOptions = [];
            let FilterDuplicate = res.data.content.filter(
              (v, i, a) =>
                a.findIndex((v2) => v2.showUniqueSlug === v.showUniqueSlug) ===
                i
            );
            FilterDuplicate.forEach((data) => {
              showOptions.push({
                value: data.showUniqueSlug,
                label: data.showTitle,
              });
            });
            this.setState({ show_slug: showOptions });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  render() {
    const {
      title,
      subtitle,
      color,
      content_type,
      auto_generation_method,
      content_slug,
      is_active,
      background_image,
      thumbnail_image,
      short_url,
      sequence,
      ContentData,
      contentTypeData,
      AutoGenerationData,
      backgroundImageView,
      ThumbnailImageView,
      showDataForEpisode,
      show_slug,
    } = this.state;
    // console.log(content_slug.length > 0,);
    return (
      <div>
        <ToastContainer
          autoClose={5000}
          hideProgressBar={false}
          position="top-center"
        />
        <Form onSubmit={(e) => this.submitHandler(e)}>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>
                  Section Name: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Section Name here"
                  name="title"
                  value={title}
                  onChange={this.changeHandler}
                  required
                />
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>
                  Section subtitle: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter subtitle here"
                  name="subtitle"
                  value={subtitle}
                  onChange={this.changeHandler}
                />
              </Col>
            </Form.Row>
          </Form.Group>
          {/* Images here */}
          <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Row>
              <Col>
                <Form.Label>
                  background Image Upload: <span className="asterisk"> </span>
                </Form.Label>
                <br></br>
                {backgroundImageView ? (
                  <Figure>
                    <Figure.Image
                      src={backgroundImageView}
                      thumbnail
                      width={171}
                      height={180}
                    />
                  </Figure>
                ) : (
                  <p>No image selected</p>
                )}
                <Form.File
                  name="background_image"
                  label="background Image Upload"
                  accept="image/*"
                  onChange={this.onbackgroundImageChange}
                  custom
                />
              </Col>
              <Col>
                <Form.Label>
                  Thumbnail Image Upload: <span className="asterisk"> </span>
                </Form.Label>
                <br></br>
                {ThumbnailImageView ? (
                  <Figure>
                    <Figure.Image
                      src={ThumbnailImageView}
                      thumbnail
                      width={171}
                      height={180}
                    />
                  </Figure>
                ) : (
                  <p>No image selected</p>
                )}
                <Form.File
                  name="thumbnail_image"
                  label="Thumbnail Image Upload"
                  accept="image/*"
                  onChange={this.onThubmnailImageChange}
                  custom
                />
              </Col>
            </Form.Row>
          </Form.Group>

          <Form.Group>
            <Form.Row>
              <Col>
                <Form.Label>
                  Section color: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  type="color"
                  name="color"
                  value={color}
                  onChange={this.changeHandler}
                />
              </Col>
              <Col>
                <Form.Label>Short URL: </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short URL here"
                  name="short_url"
                  value={short_url}
                  onChange={this.changeHandler}
                  required
                />
              </Col>
              <Col>
                <Form.Label>sequence: </Form.Label>
                <Form.Control
                  type="number"
                  // placeholder="Enter Short URL here"
                  name="sequence"
                  value={sequence}
                  onChange={this.changeHandler}
                  required
                />
              </Col>
              <Col>
                <div style={{ display: "flex" }}>
                  <Form.Label>
                    is_active: <span className="asterisk"> </span>
                  </Form.Label>{" "}
                  <br></br>
                  <Form.Control
                    style={{
                      width: "14px",
                      height: "27px",
                      marginLeft: "12px",
                    }}
                    type="checkbox"
                    name="is_active"
                    checked={is_active}
                    onClick={this.handelIsActive}
                  />
                </div>
              </Col>
            </Form.Row>
          </Form.Group>
          <Form.Group>
            <Form.Row>
              <Col>
                {/* CONTANT TYPE  HERE */}
                <Form.Label>
                  contant Type: <span className="asterisk"> *</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  name="content_type"
                  styles={{
                    control: (styles) => ({ ...styles, width: 300 }),
                  }}
                  value={content_type}
                  onChange={this.changeHandler}
                  required
                >
                  {contentTypeData.length == 0 ? (
                    <option defaultValue value="">
                      Loading...
                    </option>
                  ) : (
                    <option defaultValue value="">
                      Select Action
                    </option>
                  )}
                  {contentTypeData.map((data, index) => (
                    <option key={index} value={data}>
                      {data}
                    </option>
                  ))}
                </Form.Control>
              </Col>
              <Col>
                {/* auto generation method HERE */}
                {content_type === "SHOW" || content_type === "EPISODE" ? (
                  <>
                    <Form.Label> auto generation method :</Form.Label>
                    <Form.Control
                      as="select"
                      name="auto_generation_method"
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      value={auto_generation_method}
                      onChange={this.changeHandler}
                      style={{ width: "350px" }}
                    >
                      {AutoGenerationData.length == 0 ? (
                        <option defaultValue value="">
                          Loading...
                        </option>
                      ) : (
                        <option defaultValue value="">
                          Select Action
                        </option>
                      )}
                      {AutoGenerationData.map((data, index) => (
                        <option key={index} value={data}>
                          {data}
                        </option>
                      ))}
                    </Form.Control>
                  </>
                ) : (
                  <>
                    {" "}
                    <Form.Label> auto generation method :</Form.Label>
                    <Form.Control
                      as="select"
                      name="auto_generation_method"
                      styles={{
                        control: (styles) => ({ ...styles, width: 300 }),
                      }}
                      value={auto_generation_method}
                      onChange={this.changeHandler}
                      style={{ width: "350px" }}
                    >
                      {AutoGenerationData.length == 0 ? (
                        <option defaultValue value="">
                          Loading...
                        </option>
                      ) : (
                        <option defaultValue value="">
                          Select Action
                        </option>
                      )}
                      {/* {AutoGenerationData.map((data, index) => ( */}
                      <option value={"CUSTOM"}>{"CUSTOM"}</option>
                      {/* ))} */}
                    </Form.Control>
                  </>
                )}
              </Col>
              {auto_generation_method === "CUSTOM" ? (
                <>
                  <Col>
                    {content_type === "EPISODE" ? (
                      <>
                        <Form.Label> Show:</Form.Label>
                        <Select
                          isMulti
                          defaultValue
                          value={show_slug}
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          name="show_slug"
                          onChange={this.changeEpisode}
                          isLoading={this.state.isLoading}
                          options={showDataForEpisode}
                        />
                      </>
                    ) : null}
                  </Col>
                  <Col>
                    {/****************** * CONTANT  SLUG HERE **************************************/}

                    <Form.Label> contant slug:</Form.Label>
                    {content_type === "SHOW" ||
                    content_type === "EPISODE" ||
                    content_type === "TAG_SHOWS" ? (
                      <>
                        <Select
                          isMulti
                          defaultValue
                          value={content_slug}
                          styles={{
                            control: (styles) => ({ ...styles, width: 300 }),
                          }}
                          name="content_slug"
                          onChange={this.selectHandler}
                          isLoading={this.state.isShowLoading}
                          options={ContentData}
                        />
                      </>
                    ) : (
                      // FOR CHANNEL AND CATEGORY
                      <Form.Control
                        as="select"
                        name="content_slug"
                        styles={{
                          control: (styles) => ({ ...styles, width: 300 }),
                        }}
                        value={content_slug}
                        onChange={this.changeHandler}
                      >
                        {ContentData.length == 0 ? (
                          <option defaultValue value="">
                            Loading...
                          </option>
                        ) : (
                          <option defaultValue value="">
                            Select Action
                          </option>
                        )}
                        {ContentData.map((data, index) => (
                          <option key={index} value={data.uniqueSlug}>
                            {data.name}
                          </option>
                        ))}
                      </Form.Control>
                    )}
                  </Col>
                </>
              ) : null}
            </Form.Row>
          </Form.Group>
          {this.props.update ? (
            <Button variant="info" onClick={(e) => this.UpdateDashboard(e)}>
              update
            </Button>
          ) : (
            <Button type="submit" variant="info">
              Publish
            </Button>
          )}
        </Form>
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Table, Button, Modal, Form, Col, Figure, ListGroup, Container, Row } from 'react-bootstrap';
import ContentLoader from "react-content-loader"
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import { getAllCategoryPagination, removeCategory, updateCategory, baseURL, getAllCategory, categorySequnce } from '../../../services/serviceCategories';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import auth from '../../../services/authentication';

class AllCategory extends Component {


  constructor(props) {
    super(props)

    this.state = {
      next: "not null",
      totalCategory: 0,
      paginationCount: 0,
      currentPage: 1,
      category: [],
      isLoading: false,
      isEdit: false,
      imageUpdating: false,
      editedCategory: undefined,
      isSequenceDisabled: true
    }
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  }

  showLoader = () => {
    this.setState({ isLoading: true });
  }

  fetchData = async (page = 1) => {
    let pageNo = page
    this.showLoader();
    await getAllCategory()
      .then((response) => {
        console.log("category data", response.data)
        this.setState({ category: response.data, totalCategory: response.data.length })
        this.hideLoader();

      })
      .catch((error) => {
        toast.error("Error occured while fetching data")
        console.log(error)
        this.hideLoader();
      })
  }

  async componentDidMount() {
    this.showLoader();
    this.fetchData(this.state.currentPage)
  }

  async onImageUpload(uniqueSlug, event) {
    let formData = new FormData();
    switch (event.target.name) {
      case 'featured_image':
        formData.append('featured_image', event.target.files[0])
        break;
      default:
        formData.append('icon_image', event.target.files[0])
    }
    await axios({
      url: `${baseURL}/category/${uniqueSlug}/`,
      method: "PATCH",
      headers: {
        "Content-Type": "multipart/form-data"
      },
      data: formData,
      auth: auth
    })
      .then(res => {
        if (res.status === 200) {
          this.setState({ editedCategory: { ...this.state.editedCategory, featuredImageUrl: res.data.featuredImageUrl } })
          this.setState({ editedCategory: { ...this.state.editedCategory, iconImageUrl: res.data.iconImageUrl } })
        }
      })
      .catch(err => {
        toast.error('Image upload failed!')
        console.log(err)
      })
  }

  async onDelete(uniqueSlug, name) {
    this.showLoader()
    await removeCategory(uniqueSlug)
      .then(() => {
        toast.success(`Category "${name}" deleted successfully!`)
        if (this.state.next === null && this.state.category.length < 2 && this.state.currentPage !== 1) {
          this.setState({ currentPage: this.state.currentPage - 1 }, () => this.fetchData(this.state.currentPage))
        }
        else {
          this.fetchData(this.state.currentPage)
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          toast.error('Show is linked to category')
          this.hideLoader()
        }
        this.hideLoader()
      });
  }

  handleClose = () => {
    this.setState({ isEdit: false })
  };
  handleShow = () => {
    this.setState({ isEdit: true });
  }

  toggleEdit = (id) => {
    this.props.history.push(`/category/edit-category/${id}`)
  }

  handlePageClick = data => {
    this.showLoader()
    let selected = data.selected + 1;
    this.setState({ currentPage: selected }, () => this.fetchData(this.state.currentPage))
  };
  handleRLDDChange = (newItems) => {
    let data = this.state.category
    let newArr = newItems
    let newEpi = newArr.map((item, index) => {
      return { ...item, sequence: index + 1 }
    })
    this.setState({ category: newEpi, isSequenceDisabled: false });
  }
  saveSequence = () => {
    let epiSlugs = this.state.category.map(item => item.uniqueSlug)
    console.log(epiSlugs)
    categorySequnce(epiSlugs)
      .then(() => {
        this.setState({ isSequenceDisabled: true }, () => toast.success('Sequence updated successfully!'))
      })
      .catch(err => {
        toast.error("Couldn't update the sequence at the moment!")
        console.log('Episode sequence error: ', err)
      })
  }
  render() {

    const { category } = this.state
    let imgHash = Date.now()
    const loader = <ContentLoader backgroundColor="#c2c2c2"><rect x="0" y="56" rx="3" ry="3" width="150" height="4" /><rect x="0" y="72" rx="3" ry="3" width="100" height="4" /></ContentLoader>

    return (

      <div>
        <ToastContainer />
        <h2>Category list ({this.state.totalCategory})    <Button variant="primary" disabled={this.state.isSequenceDisabled} onClick={() => this.saveSequence()}>Save Sequence</Button></h2>

        <ListGroup>
          <ListGroup.Item variant="secondary">
            <Container fluid>
              <Row style={{ textAlign: 'center' }}>
                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                  <strong>Sr.no</strong>
                </Col>
                <Col className>
                  <strong>Category Name</strong>
                </Col>
                <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                  <strong>Seq No.</strong>
                </Col>
                <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                  <strong>Actions</strong>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
          {!this.state.isLoading ?
            <RLDD
              id
              items={category}
              itemRenderer={(data) => {
                return (

                  <ListGroup.Item action>
                    <Container fluid>
                      <Row style={{ textAlign: 'center' }}>
                        <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                          <strong>{data.id}</strong>
                        </Col>
                        <Col>
                          <strong>{data.name}</strong>
                        </Col>
                        <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                          <strong>{data.sequence}</strong>
                        </Col>
                        <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                          <Button variant="primary" onClick={() => this.toggleEdit(data.uniqueSlug)}>Edit</Button>
                          <Button variant="danger" onClick={() => { if (window.confirm(`Are you sure you wish to delete category ${data.name}?`)) this.onDelete(data.uniqueSlug, data.name) }}>Delete</Button>
                        </Col>
                      </Row>
                    </Container>

                  </ListGroup.Item>
                );
              }}
              onChange={this.handleRLDDChange}
            />

            :
            <Row style={{ textAlign: 'center' }}>
              <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                {loader}
              </Col>
              <Col>
                {loader}
              </Col>
              <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                {loader}
              </Col>
              <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                {loader}
              </Col>

            </Row>}

        </ListGroup>
      </div>
    )
  }
}

export default AllCategory

import http from '../http-common';
import auth from './authentication';
const baseURL = "https://preprod.aawaz.com";


//  RJ apis
const AddRj = (data) => {
    return http.post(`${baseURL}/live_shows/rjs/`, data, { auth: auth });
}
const RjLogin = (data) => {
    return http.post(`${baseURL}/live_shows/login/`, data, { auth: auth });
}
const ListRj = (page = 1) => {
    if (page === 1) {
        return http.get(`${baseURL}/live_shows/rjs/`, { auth: auth })
    }
    else {
        return http.get(`${baseURL}/live_shows/rjs/?limit=10&offset=${page - 1}0`, { auth: auth });
    }

}
const AllListRj = () => {
    return http.get(`${baseURL}/live_shows/rjs/?all=True`, { auth: auth });


}
const UpdateRj = (id, data) => {
    return http.patch(`${baseURL}/live_shows/rjs/rj-${id}/`, data, { auth: auth });
}
const deleteRj = id => {
    return http.delete(`${baseURL}/live_shows/rjs/${id}/`, { auth: auth });
}


//  Live shows apis
const AddLiveShow = (data) => {
    return http.post(`${baseURL}/live_shows/shows/`, data, { auth: auth });
}
const allLiveShowDropdown = () => {
    return http.get(`${baseURL}/live_shows/shows/?all=True&only_select=True`, { auth: auth });
}
const LiveShowDashboard = () => {
    return http.get(`${baseURL}/live_shows/dashboard/`, { auth: auth });
}
const LiveShowList = (page = 1) => {
    if (page === 1) {
        return http.get(`${baseURL}/live_shows/shows/`, { auth: auth })
    }
    else {
        return http.get(`${baseURL}/live_shows/shows/?limit=10&offset=${page - 1}0`, { auth: auth });
    }
}
const UpdateLiveShow = (id, data) => {
    return http.patch(`${baseURL}/live_shows/shows/${id}/`, data, { auth: auth });
}
const DeleteLiveShow = (id) => {
    return http.delete(`${baseURL}/live_shows/shows/${id}/`, { auth: auth });
}
//    Guest apis

const createGuest = (data) => {
    return http.post(`${baseURL}/live_shows/guests/`, data, { auth: auth });
}
const guestList = () => {
    return http.get(`${baseURL}/live_shows/guests/`, { auth: auth });
}
const AllGuestList = () => {
    return http.get(`${baseURL}/live_shows/guests/?all=True`, { auth: auth });
}
const UpdateGuest = (id, data) => {
    return http.patch(`${baseURL}/live_shows/guests/guest-${id}/`, data, { auth: auth });
}
export {
    AddRj,
    ListRj,
    UpdateRj,
    deleteRj,
    AddLiveShow,
    LiveShowDashboard,
    LiveShowList,
    RjLogin,
    UpdateLiveShow,
    DeleteLiveShow,
    allLiveShowDropdown,
    createGuest,
    AllGuestList,
    AllListRj,
    guestList,
    UpdateGuest,
    baseURL
};
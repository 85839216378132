import React, { Component } from "react";
import {
  Button,
  Form,
  Col,
  Figure,
  Card,
  Accordion,
  Alert,
  Modal,
  Container,
  Row,
  ListGroup,
} from "react-bootstrap";
import { Pencil, Trash } from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  GetProductById,
  deletePlans,
} from "../../../../services/serviceSubscription";
import AddPlan from "./AddPlan";
import ContentLoader from "react-content-loader";

class EditProductPlans extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Product: {},
      Plans: [],
      isModel: false,
      isEdit: false,
      editedData: {},
    };
  }
  componentDidMount() {
    this.fetchData();
  }
  fetchData = () => {
    GetProductById(this.props.match.params.unique)
      .then((res) => {
        this.setState({
          Product: res.data,
          Plans: res.data.plans,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleModalClose = () => {
    this.setState({ isModel: !this.state.isModel });
  };
  handelEditData = (data) => {
    this.setState({ editedData: data, isEdit: true });
    this.handleModalClose();
  };
  handelAddData = () => {
    this.setState({ editedData: {}, isEdit: false });
    this.handleModalClose();
  };
  deletPlanData = async (id) => {
    this.setState({ isLoading: true });
    await deletePlans(id)
      .then((res) => {
        this.fetchData();
        toast.success("plan delet sucessfully!");
      })
      .catch((err) => {
        toast.success("plan delet failed!");
      });
  };
  render() {
    let { Product, Plans, isModel, isEdit, editedData } = this.state;

    return (
      <div>
        <ToastContainer />
        <Button variant="warning" onClick={() => this.handelAddData()}>
          Add Plan
        </Button>
        {/* MODEL FOR ADD/EDIT PLAN */}
        <Modal size="xl" show={isModel} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Episode</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <AddPlan
              id={this.props.match.params.unique}
              isEdit={isEdit}
              data={editedData}
              handleModalClose={this.handleModalClose}
              fetch={this.fetchData}
            />
          </Modal.Body>
        </Modal>
        {Plans.length === 0 ? (
          <Alert variant="danger">
            No plans found. Please Add plans to the product.
          </Alert>
        ) : (
          <div>
            <Card>
              <Card.Body>
                <h5>Product name: {Product.name}</h5>
                {/* <h6>Total episodes: {Product.plans.length}</h6> */}
                <ListGroup>
                  <ListGroup.Item variant="secondary">
                    <Container fluid>
                      <Row style={{ textAlign: "center" }}>
                        <Col>
                          <strong>Name</strong>
                        </Col>
                        <Col className>
                          <strong>Description</strong>
                        </Col>
                        <Col>
                          <strong>Created On</strong>
                        </Col>
                        <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                          <strong>Amount</strong>
                        </Col>

                        <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                          <strong>Actions</strong>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>

                  {Plans.map((data) => (
                    <ListGroup.Item action>
                      <Container fluid>
                        <Row style={{ textAlign: "center" }}>
                          <Col>
                            <strong>{data.name}</strong>
                          </Col>
                          <Col>
                            <strong>{data.description}</strong>
                          </Col>
                          <Col>
                            <strong>{data.createdOn.toLocaleString()}</strong>
                          </Col>
                          <Col className="col-sm-12 col-md-1 col-lg-1 col-xl-1">
                            <strong>{data.amount}</strong>
                          </Col>
                          <Col className="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                            <Button
                              variant="outline-danger"
                              onClick={() => {
                                if (
                                  window.confirm(
                                    `Are you sure you wish to delete episode "${data.name}"?`
                                  )
                                )
                                  this.deletPlanData(data.uuid);
                              }}
                            >
                              <Trash />
                            </Button>{" "}
                            <Button
                              variant="outline-info"
                              onClick={() => {
                                this.handelEditData(data);
                              }}
                            >
                              <Pencil />
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    );
  }
}

export default EditProductPlans;

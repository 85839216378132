import React, { Component } from 'react';
import { Form, Button, Container } from 'react-bootstrap';
import { withRouter, Redirect } from 'react-router-dom';
import './Login.css';
import logo from '../../images/aawaz-logo.png';


class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            error: false,
        };

        // this.handleChange = this.handleChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }
    handleChange(event) {
        event.target.name === 'username' ? this.setState({ username: event.target.value }) : this.setState({ password: event.target.value })
        // this.setState({event.target.name: event.target.value})
    }
    onSubmit(e) {
        e.preventDefault();

        const { username, password } = this.state;

        this.setState({ error: false });

        if (username === 'aawazadmin' && password === 'm8Z@p8DQ') {

            console.log("you're logged in. yay! admin");
            localStorage.setItem('LoggedIn', 'true');
            this.props.history.push(`/`)

        } else if (username === 'rjaawazadmin' && password === 'rjaawazadmin') {
            console.log("you're logged in. yay! rj");
            localStorage.setItem('LoggedIn', 'true');
            this.props.history.push(`/rj-profile`)
        } else {
            alert("wrong Credentials")
            return this.props.history.push(`/`)
        }
    }
    // if (isLoggedIn()) {

    //   }

    render() {
        const { error } = this.state;
        return (
            <React.Fragment className="bg-login">

                <div className="login-screen">
                    <div className="login-form-header">
                        <img src={logo} className="logo" alt="Aawaz logo" />
                        <h2 className="login-title">Login Page</h2>
                    </div>
                    <Form className="form-body" error={error} onSubmit={this.onSubmit}>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control className="login-input" type="text" placeholder="Enter username" name="username" onChange={(event) => this.handleChange(event)} />
                            <Form.Text className="text-muted">
                                Never share your username and password with anyone.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                            <Form.Control className="login-input" type="password" placeholder="Password" name="password" onChange={(event) => this.handleChange(event)}
                            />
                        </Form.Group>
                        <Button className="login-button" variant="outline-light" type="submit">
                            Login
                        </Button>
                    </Form>
                </div>

            </React.Fragment>
        );
    }
}

export default withRouter(Login);
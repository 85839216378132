import React, { Component } from "react";
import { Link } from "react-router-dom";
import { NavDropdown, Button } from "react-bootstrap";

class SidebarNav extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    LoggedIn: false,
  };
  logout = () => {
    localStorage.removeItem("LoggedIn");
    this.props.history.push("/");
    console.log("you're logged out  ");
  };
  componentDidMount() {
    let loginCheck = localStorage.getItem("LoggedIn");
    if (loginCheck) {
      this.setState({
        LoggedIn: true,
      });
    } else {
      this.setState({
        LoggedIn: false,
      });
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="sidebar-sticky">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link to="/" exact="true" className="nav-link active">
                Dashboard
              </Link>
            </li>
            <li>
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-ad"></i>Ad
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/createAd" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Create Ad
                </Link>
                <Link to="/AdList" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Ad lists
                </Link>
                {/* <Link to="/rss/rss-episode" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Add New Section</Link> */}
              </NavDropdown>
            </li>

            <li>
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-user"></i>Artist
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/artist" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add Artist
                </Link>
                <Link to="/all-artist" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Artist
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-bookmark"> </i> Language
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/all-language" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Language
                </Link>
                <Link to="/language" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add New Language
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-bookmark"> </i> Badges
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/badges/all-badges" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Badges
                </Link>
                <Link to="/badges/new-badges" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add New Badges
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-bacon"></i> Banners
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/banners/all-banner"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Banners
                </Link>
                <Link
                  to="/banners/new-banner"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Banner v1
                </Link>
                <Link
                  to="/banners/new-banner-v2"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Banner v2
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-bullhorn"></i> Channel
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/channel/sheet-upload"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Channel Sheet
                </Link>
                <Link
                  to="/channel/all-channel"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Channel
                </Link>
                <Link
                  to="/channel/new-channel"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Channel
                </Link>
              </NavDropdown>
            </li>

            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-list"></i> Category
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/category/sheet-upload"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i> All Category
                  Sheet
                </Link>
                <Link
                  to="/category/all-category"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Category
                </Link>
                <Link
                  to="/category/new-category"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Category
                </Link>
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-laptop-code"></i> Platforms
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/platforms/all-platforms"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Platforms
                </Link>
                <Link
                  to="/platforms/new-platforms"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Platforms
                </Link>
              </NavDropdown>
            </li>

            <li>
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-passport"></i> RSS
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/rss" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Rss List
                </Link>
                <Link to="/rss-import" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Rss Import
                </Link>
                {/* <Link to="/rss/rss-episode" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Add New Section</Link> */}
              </NavDropdown>
            </li>

            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-headphones-alt"></i> Shows
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/shows/sheet-upload"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Shows Sheet{" "}
                </Link>
                <Link to="/shows/all-show" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Shows
                </Link>
                <Link to="/shows/new-show" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add New Show
                </Link>
                {/* <Link to="/shows/tags" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Tags</Link> */}
              </NavDropdown>
            </li>

            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-puzzle-piece"></i> Section
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link
                  to="/section/all-section"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Section Sheet
                </Link>
                <Link
                  to="/section/sequece-section"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All Section
                </Link>
                <Link
                  to="/section/new-section"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add New Section
                </Link>
                <Link
                  to="/section/DashboardV3"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>Add DashboardV3
                </Link>
                <Link
                  to="/section/all-DashboardV3"
                  exact="true"
                  className="nav-link"
                >
                  <i className="fas fa-angle-double-right"></i>All DashboardV3
                </Link>
              </NavDropdown>
            </li>

            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-puzzle-piece"></i> Notification
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/Custom" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Custom
                </Link>
                {/* <Link to="/scheduled_notification" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Schedule</Link> */}
              </NavDropdown>
            </li>
            <li className="nav-item">
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-bookmark"> </i> Header
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/all-header" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Header
                </Link>
                <Link to="/header" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add New Header
                </Link>
              </NavDropdown>
            </li>
            <li>
              <NavDropdown
                title={
                  <span>
                    <i className="fas fa-ad"></i>Subscriptions
                  </span>
                }
                id="basic-nav-dropdown"
              >
                <Link to="/add-product" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>Add Product
                </Link>
                <Link to="/all-product" exact="true" className="nav-link">
                  <i className="fas fa-angle-double-right"></i>All Product
                </Link>
              </NavDropdown>
            </li>
            {/* <li>
              <NavDropdown title={<span><i className="fas fa-ad"></i>Ad</span>} id="basic-nav-dropdown">
                <Link to="/createAd" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Create Ad</Link>
                <Link to="/AdList" exact="true" className="nav-link"><i className="fas fa-angle-double-right"></i>Ad lists</Link>
              </NavDropdown>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default SidebarNav;

import React, { Component } from "react";
import { Table, Button, Modal, Form, Figure } from "react-bootstrap";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import auth from "../../../services/authentication";
import { GetAllHeader, deleteHeader } from "../../../services/servicesHeader";
import AddHeader from "./AddHeader";

class AllHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [],
      isLoading: false,
      isEdit: false,
      imageUpdating: false,
      editedheader: undefined,
    };
  }

  hideLoader = () => {
    this.setState({ isLoading: false });
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  async componentDidMount() {
    //Load all the header
    this.showLoader();
    await GetAllHeader()
      .then((response) => {
        console.log(response.data);
        this.setState({ header: response.data });
        this.hideLoader();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async onDelete(id, platform) {
    await deleteHeader(id)
      .then((response) => {
        if (response.status === 204) {
          alert(`header ${platform} deleted successfully.`);
          this.componentDidMount();
        }
      })
      .catch((err) => {
        console.log(err);
        alert(`header ${platform} delete failed, try again later.`);
      });
  }

  handleClose = () => {
    this.setState({ isEdit: false });
  };
  handleShow = () => {
    this.setState({ isEdit: true });
  };

  toggleEdit = (show) => {
    this.setState({
      editedheader: show,
      isEdit: !this.isEdit,
    });
  };

  render() {
    const { header } = this.state;

    return (
      <div>
        <ToastContainer />
        {this.state.isEdit ? (
          <Modal size="xl" show={this.state.isEdit} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Header</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddHeader isEdit={true} data={this.state.editedheader} />
            </Modal.Body>
          </Modal>
        ) : null}
        <h2>List of Headers</h2>
        {this.state.isLoading ? (
          <Loader type="ThreeDots" color="#eb1163" height={100} width={50} />
        ) : (
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">Sr.no</th>
                <th scope="col">Background Image</th>
                <th scope="col">Header Decoration</th>
                <th scope="col">logo</th>
                <th scope="col">platfrom</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            <tbody>
              {header.length
                ? header.map((header, index) => (
                    <tr key={index}>
                      <td key={index}>{index + 1}</td>
                      <td>
                        <Figure>
                          <Figure.Image
                            src={header.backgroundImageUrl}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      </td>
                      <td>
                        <Figure>
                          <Figure.Image
                            src={header.headerDecorationUrl}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      </td>
                      <td>
                        <Figure>
                          <Figure.Image
                            src={header.logoUrl}
                            thumbnail
                            width={171}
                            height={180}
                          />
                        </Figure>
                      </td>
                      <td>{header.platform.name}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => this.toggleEdit(header)}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="danger"
                          onClick={() => {
                            if (
                              window.confirm(
                                `Are you sure you wish to delete header ${header.platform}?`
                              )
                            )
                              this.onDelete(header.uniqueSlug, header.platform);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))
                : null}
            </tbody>
          </Table>
        )}
      </div>
    );
  }
}

export default AllHeader;
